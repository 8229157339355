import { userLogin, userRegister } from "../../axios/ServerRequest";
import LocalStorage from "../../utils/LocalStorage";
import { constants } from "../constants";

export function isLoading(bool) {
  return {
    type: constants.LOGIN_REQUEST,
    isLoading: bool,
  };
}

export function loginSuccess(userDetails) {
  return {
    type: constants.LOGIN_SUCCESS,
    userDetails,
  };
}

export function loginFailed(error) {
  return {
    type: constants.LOGIN_FAILURE,
    error,
  };
}

export const login = (data, type) => (dispatch) => {
  dispatch(isLoading(true));
  if (type === "login") {
    return userLogin(data)
      .then((res) => {
        LocalStorage.setUserDetails(res.data.user);
        LocalStorage.setApiKey(res.data.token_type + " " + res.data.token);
        dispatch(loginSuccess(res.data));
      })
      .catch((error) => {
        dispatch(loginFailed(error.response.data));
      });
  } else {
    return userRegister(data)
      .then((res) => {
        LocalStorage.setUserDetails(res.data.user);
        LocalStorage.setApiKey(res.data.token_type + " " + res.data.token);
        dispatch(loginSuccess(res.data));
      })
      .catch((error) => {
        dispatch(loginFailed(error.response.data));
      });
  }
};
