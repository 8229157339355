import React, { useEffect } from "react";
import { connect } from "react-redux";
import { allCategoryData } from "../../redux/actions/CategoryAction";

function NavBar(props) {
  useEffect(() => {
    props.allCategoryData();
  }, []);

  return (
    <nav className="navbar navbar-expand-lg navbar-light osahan-menu-2 pad-none-mobile">
      <div className="container-fluid">
        <div className={`navbar-collapse collapse ${props.show?"show":''}`} id="navbarText">
          <ul className="navbar-nav mr-auto mt-2 mt-lg-0 margin-auto">
            {props.data &&
              props.data.length > 0 &&
              props.data.slice(0, 8).map((item, index) => {
                return (
                  <li className="nav-item dropdown" key={index}>
                    <a
                      className={`nav-link ${
                        item.child_cat.length > 0 ? "dropdown-toggle" : ""
                      }`}
                      href={`/shop/${item.slug}`}
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                      target="_blank"
                    >
                      {item.title}
                    </a>
                    {item.child_cat.length > 0 ? (
                      <div className="dropdown-menu">
                        {item.child_cat.map((item1, i) => {
                          return (
                            <a
                              className="dropdown-item"
                              href={`/shop/${item.slug}/${item1.slug}`}
                              key={i}
                              target="_blank"
                            >
                              <i
                                className="mdi mdi-chevron-right"
                                aria-hidden="true"
                              ></i>{" "}
                              {item1.title}
                            </a>
                          );
                        })}
                      </div>
                    ) : null}
                  </li>
                );
              })}

          </ul>
        </div>
      </div>
    </nav>
  );
}

const mapStoreToProps = (state) => ({
  isLoading: state.CategoryReducer.isLoading,
  data: state.CategoryReducer.categories,
  error: state.CategoryReducer.error,
});
const mapDispatchToProps = (dispatch) => ({
  allCategoryData: () => dispatch(allCategoryData()),
});

export default connect(mapStoreToProps, mapDispatchToProps)(NavBar);
