import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import Logo from "../../assets/images/logo.png";
import { BASE_URL } from "../../axios/API";
import LocalStorage from "../../utils/LocalStorage";
import Login from "../Auth";
import CartMenu from "../CartMenu";
import { getSearchProduct } from "../../axios/ServerRequest";
import InfiniteScroll from "react-infinite-scroll-component";

function TopNavBar(props) {
  const [modalShow, setModalShow] = React.useState(false);
  const [location, setLocation] = React.useState();
  const [text, setText] = React.useState("");
  const [page, setPage] = React.useState(1);
  const [data, setData] = React.useState([]);
  const [end, setEnd] = useState(false);

  const user = LocalStorage.getUserDetails();

  const handleClose = () => setModalShow(false);
  const handleShow = () => setModalShow(true);

  const setting = LocalStorage.getSetting();
  const userLocation = LocalStorage.getUserLocation();
  let locations = new Array();
  if (setting && setting.availability) {
    locations = setting.availability.split(",");
  }

  const locationChange = (e) => {
    setLocation(e.target.value);
    LocalStorage.setUserLocation(e.target.value);
  };

  const fetchData = async (text, page) => {
    await getSearchProduct(text, page)
      .then((response) => {
        if (response.status === 200) {
          if (page === 1) {
            setData(response.data.products.data);
          } else {
            setData([...data, ...response.data.products.data]);
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const fetchMoreData = () => {
    setPage(page + 1);
    fetchData(text, page + 1);
  };

  const logoutUser = () => {
    LocalStorage.logout();
    window.location.reload();
  };

  return (
    <nav className="navbar navbar-light navbar-expand-lg bg-success bg-faded osahan-menu">
      <div className="container-fluid">
        <a className="navbar-brand" href="/">
          {setting && setting.logo ? (
            <img
              src={BASE_URL + setting.logo}
              alt={setting.title}
              style={{ height: 50 }}
            />
            
          ) : null}
          <h1 style={{ display:'none' }}>Front End Source Code</h1>
        </a>

        <button
          className="navbar-toggler navbar-toggler-white"
          type="button"
          data-toggle="collapse"
          data-target="#navbarText"
          aria-controls="navbarText"
          aria-expanded="false"
          aria-label="Toggle navigation"
          onClick={()=>props.setShow(!props.show)}
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="navbar-collapse" id="navbarNavDropdown">
          <div className="navbar-nav mr-auto mt-2 mt-lg-0 margin-auto top-categories-search-main position-static">
            <div className="top-categories-search">
              <div className="input-group">
                <span className="input-group-btn categories-dropdown">
                  <select
                    className="form-control"
                    onChange={locationChange}
                    value={location ? location : userLocation}
                  >
                    <option selected="selected">Your City</option>
                    {locations &&
                      locations.map((item, index) => {
                        return (
                          <option
                            value={item}
                            key={index}
                            selected={userLocation === item ? true : false}
                          >
                            {item}
                          </option>
                        );
                      })}
                  </select>
                </span>
                <input
                  className="form-control"
                  placeholder="Search products in Your City"
                  aria-label="Search products in Your City"
                  type="text"
                  value={text}
                  onChange={(e) => {
                    fetchData(e.target.value, 1);
                    setText(e.target.value);
                  }}
                />
                <span className="input-group-btn">
                  <button className="btn btn-secondary" type="button">
                    <i className="mdi mdi-file-find"></i> Search
                  </button>
                </span>
              </div>
            </div>
            {text.length > 0 ? (
              <div
                className="card position-absolute"
                style={{
                  top: 55,
                  width: "50%",
                  left: "25%",
                  maxHeight: 250,
                  zIndex: "99999",
                }}
              >
                <div className="card-body" style={{ overflowY: "scroll" }}>
                  <InfiniteScroll
                    dataLength={data.length / 3}
                    next={fetchMoreData}
                    hasMore={!end}
                    loader={<p>Loading...</p>}
                  >
                    {data.map((item, index) => {
                      return (
                        <a href={`/product/${item.slug}`} target={"_blank"}>
                          <div className="row mb-2" key={index}>
                            <div className="col-md-1">
                              <img
                                src={BASE_URL + item.photo.split(",")[0]}
                                alt={item.title}
                                className="fluid"
                                style={{ maxWidth: 40 }}
                              />
                            </div>
                            <div className="col-md-11">{item.title}</div>
                          </div>
                        </a>
                      );
                    })}
                  </InfiniteScroll>
                </div>
              </div>
            ) : null}
          </div>
          <div className="my-2 my-lg-0">
            <ul className="list-inline main-nav-right">
              {user && user.id ? (
                <li className="list-inline-item dropdown">
                  <a
                    href="#"
                    data-target="#bd-example-modal"
                    data-toggle="modal"
                    className="btn btn-link dropdown-toggle"
                  >
                    <i
                      className="mdi mdi-account-circle"
                      style={{ fontSize: 22 }}
                    ></i>
                  </a>
                  <div className="dropdown-menu">
                    <a className="dropdown-item" href="/profile">
                      <i
                        className="mdi mdi-chevron-right"
                        aria-hidden="true"
                      ></i>{" "}
                      My Profile
                    </a>

                    <a
                      className="dropdown-item"
                      href="#"
                      onClick={() => logoutUser()}
                    >
                      <i aria-hidden="true" className="mdi mdi-lock"></i>
                      Logout
                    </a>
                  </div>
                </li>
              ) : (
                <li className="list-inline-item">
                  <a
                    href="#"
                    data-target="#bd-example-modal"
                    data-toggle="modal"
                    className="btn btn-link"
                    onClick={() => setModalShow(true)}
                  >
                    <i
                      className="mdi mdi-account-circle"
                      style={{ fontSize: 24 }}
                    ></i>{" "}
                    Login/Sign Up
                  </a>
                </li>
              )}
              <li className="list-inline-item cart-btn">
                <CartMenu />
              </li>
            </ul>
          </div>
        </div>
      </div>
      <Login modalShow={modalShow} handleClose={handleClose} />
    </nav>
  );
}

export default TopNavBar;
