import React, { useState } from "react";
import UserImage from "../../assets/images/user.png";
import LocalStorage from "../../utils/LocalStorage";
import {BASE_URL} from "../../axios/API";
function ProfileMenu(props) {
  const [selectedOption, setSelectedOption] = useState(0);

  const onSelectOption = (option) => {
    setSelectedOption(option);
    props.onSelectOption(option);
  };

  const user = props.user;

  const logoutUser = () => {
    LocalStorage.logout();
    window.location.reload();
  };
  return (
    <div className="card account-left">
      <div className="user-profile-header">
        {user.photo?
        <img alt="logo" src={BASE_URL+user.photo} />:
            <img src={UserImage}/>}
        <h5 className="mb-1 text-secondary">
          <strong>Hi </strong> {user?user.name:""}
        </h5>
        {user && <p> +91{user.phone}</p>}
      </div>
      <div className="list-group">
        <a
          href="#"
          onClick={() => {
            onSelectOption(0);
          }}
          className={`list-group-item list-group-item-action ${
            selectedOption === 0 ? "active" : ""
          }`}
        >
          <i aria-hidden="true" className="mdi mdi-account-outline"></i> My
          Profile
        </a>
        <a
          href="#"
          onClick={() => {
            onSelectOption(1);
          }}
          className={`list-group-item list-group-item-action ${
            selectedOption === 1 ? "active" : ""
          }`}
        >
          <i aria-hidden="true" className="mdi mdi-map-marker-circle"></i> My
          Address
        </a>
        {/*<a*/}
        {/*  href="#"*/}
        {/*  onClick={() => {*/}
        {/*    onSelectOption(2);*/}
        {/*  }}*/}
        {/*  className={`list-group-item list-group-item-action ${*/}
        {/*    selectedOption === 2 ? "active" : ""*/}
        {/*  }`}*/}
        {/*>*/}
        {/*  <i aria-hidden="true" className="mdi mdi-heart-outline"></i> Wish List{" "}*/}
        {/*</a>*/}
        <a
          href="#"
          onClick={() => {
            onSelectOption(2);
          }}
          className={`list-group-item list-group-item-action ${
            selectedOption === 2 ? "active" : ""
          }`}
        >
          <i aria-hidden="true" className="mdi mdi-format-list-bulleted"></i>{" "}
          Order List
        </a>
        <a
          href="#"
          onClick={() => logoutUser()}
          className="list-group-item list-group-item-action"
        >
          <i aria-hidden="true" className="mdi mdi-lock"></i> Logout
        </a>
      </div>
    </div>
  );
}

export default ProfileMenu;
