import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { cartData } from "../../redux/actions/CartAction";
import LocalStorage from "../../utils/LocalStorage";

function FloatingCart(props) {
  const [count, setCount] = useState(0);
  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = () => {
    const user = LocalStorage.getUserDetails();

    if (user !== null) {
      setCount(
        LocalStorage.getCartCount() > 0 ? LocalStorage.getCartCount() : 0
      );
      // props.cartData();
    }
  };
  return (
    <div
      className="position-fixed bottom-0 end-0 p-4 "
      style={{ zIndex: 9999 }}
    >
      {LocalStorage.getCartCount() === null ||
      LocalStorage.getCartCount() === 0 ? null : (
        <a href="/cart" className="btn-secondary float-cart-btn">
          Total Items:{" "}
          {props.data && props.data.total_count
            ? props.data.total_count
            : LocalStorage.getCartCount()}
        </a>
      )}
    </div>
  );
}

const mapStoreToProps = (state) => ({
  isLoading: state.CartReducer.isLoading,
  data: state.CartReducer.cart,
  error: state.CartReducer.error,
});
const mapDispatchToProps = (dispatch) => ({
  // cartData: () => dispatch(cartData()),
});

export default connect(mapStoreToProps, mapDispatchToProps)(FloatingCart);
