import {constants} from '../constants';

const initialState = {
    isLoading: false,
    categories:{},
    error:undefined
};

export function CategoryReducer(state = initialState, action) {
  switch (action.type) {
    case constants.CATEGORY_REQUEST:
      return {
        ...state,
        isLoading: true,
        categories: action.categories,
      };
    case constants.CATEGORY_SUCCESS:
      return {
        ...state,
        isLoading: false,
        categories: action.categories.categories,
        error:undefined
      };
    case constants.CATEGORY_FAILURE:
      return {
        ...state,
        isLoading: false,
        error:action.error
      };

    default:
      return state;
  }
}
