import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { addCartData } from "../../redux/actions/CartAction";

import { BASE_URL } from "../../axios/API";
import LocalStorage from "../../utils/LocalStorage";
import CartItem from "./CartItem";
import Loader from "../../components/Loader";
import { Helmet } from "react-helmet";
import NoCart from "../../assets/images/nocart.png";

function Cart(props) {
  const setting = LocalStorage.getSetting();
  const [cart, setCart] = useState([]);
  const [total_amount, setTotalAmount] = useState(0);

  useEffect(() => {
    if (props.data && props.data.cart) {
      setCart(props.data.cart);
      setTotalAmount(props.data.total_amount);
    }
  }, [props.data]);

  return (
    <div>
      <section className="pt-3 pb-3 page-info section-padding border-bottom bg-white">
        <Helmet>
          <meta charSet="utf-8" />
          <title>{setting.title} | Cart</title>
          <link rel="canonical" href={window.location.href} />
          <meta
            name="description"
            content={setting.short_des}
            data-react-helmet="true"
          />
          <meta
            name="keywords"
            content={setting.meta_tags}
            data-react-helmet="true"
          />
          <meta
            name="image"
            content={BASE_URL + setting.logo}
            data-react-helmet="true"
          />

          <meta
            property="og:title"
            content={setting.title}
            data-react-helmet="true"
          />

          <meta
            property="og:url"
            content={window.location.href}
            data-react-helmet="true"
          />
          <meta
            property="og:description"
            content={setting.short_des}
            data-react-helmet="true"
          />
          <meta property="og:type" content="website" data-react-helmet="true" />
          <meta
            name="og:image"
            content={BASE_URL + setting.logo}
            data-react-helmet="true"
          />
          <meta
            name="twitter:card"
            content="summary_large_image"
            data-react-helmet="true"
          />
          <meta
            name="twitter:creator"
            content="@frontendsourcecode"
            data-react-helmet="true"
          />
          <meta
            name="twitter:title"
            content={setting.title}
            data-react-helmet="true"
          />
          <meta
            name="twitter:description"
            content={setting.short_des}
            data-react-helmet="true"
          />
          <meta
            name="twitter:image"
            content={BASE_URL + setting.logo}
            data-react-helmet="true"
          />
        </Helmet>
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <a href="#">
                <strong>
                  <span className="mdi mdi-home"></span> Home
                </strong>
              </a>{" "}
              <span className="mdi mdi-chevron-right"></span>{" "}
              <a href="#">Cart</a>
            </div>
          </div>
        </div>
      </section>
      <section className="cart-page section-padding">
        {cart && cart !== null && cart.length > 0 ? (
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="card card-body cart-table">
                  <div className="table-responsive">
                    <table className="table">
                      <thead>
                        <tr>
                          <th className="cart_product">Product</th>
                          <th>Description</th>
                          <th>Avail.</th>
                          <th>Unit price</th>
                          <th>Qty</th>
                          <th className="text-center">Total</th>
                          <th className="action">
                            <i className="mdi mdi-delete-forever"></i>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {cart.map((item, index) => {
                          return (
                            <CartItem
                              item={item}
                              setting={setting}
                              key={index}
                            />
                          );
                        })}
                      </tbody>
                      <tfoot>
                        <tr>
                          <td colspan="4"></td>
                          <td className="text-right" colspan="1">
                            <strong>Total : </strong>
                          </td>

                          <td className="text-center" colspan="1">
                            <strong>
                              {setting && setting.currency} {total_amount}
                            </strong>
                          </td>
                          <td colspan="1"></td>
                        </tr>
                      </tfoot>
                    </table>
                  </div>
                  <div className="d-flex justify-content-end">
                    <a href="/checkout">
                      <button
                        className="btn btn-secondary btn-lg btn-block text-left"
                        type="button"
                      >
                        <span className="float-left">
                          <i className="mdi mdi-cart-outline"></i> Proceed to
                          Checkout{" "}
                        </span>
                      </button>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="text-center">
            <div className="d-flex flex-column justify-content-center align-items-center">
              <img src={NoCart} />
              <b>No products available in the cart</b>
            </div>
          </div>
        )}
      </section>
      <Loader loading={props.isLoading} />
    </div>
  );
}

const mapStoreToProps = (state) => ({
  isLoading: state.CartReducer.isLoading,
  data: state.CartReducer.cart,
  error: state.CartReducer.error,
});
const mapDispatchToProps = (dispatch) => ({});

export default connect(mapStoreToProps, mapDispatchToProps)(Cart);
