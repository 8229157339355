import React from "react";
import OwlCarousel from "react-owl-carousel";
import { BASE_URL } from "../../axios/API";

function TopCategorySection({ categories }) {
  const options = {
    loop: true,
    margin: 10,
    autoplay: true,
    nav: true,
    dots: false,
    responsive: {
      0: {
        items: 3,
      },
      600: {
        items: 4,
      },
      1000: {
        items: 6,
      },
      1200: {
        items: 8,
      },
    },
  };
  return (
    <section className="top-category section-padding">
      <div className="container p-3">
        <OwlCarousel
          className="owl-theme owl-carousel owl-carousel-category"
          {...options}
        >
          {categories &&
            categories.length > 0 &&
            categories.map((item, index) => {
              return (
                <div className="item" key={index}>
                  <div className="category-item">
                    <a href={`/shop/${item.slug}`} target="_blank">
                      <img
                        className="img-fluid"
                        src={BASE_URL + item.photo}
                        alt=""
                      />
                      <h6>{item.title}</h6>
                      <p>{item.products_count} Items</p>
                    </a>
                  </div>
                </div>
              );
            })}
        </OwlCarousel>
      </div>
    </section>
  );
}

export default TopCategorySection;
