import React from "react";
import { Badge } from "react-bootstrap";
import OwlCarousel from "react-owl-carousel";
import ProductItem from "../../components/ProductItem";

function TopCategoryProduct(props) {
  const { data, setting } = props;
  const {categoryProducts} =data;

  return (
      <>{
        categoryProducts && categoryProducts.length>0 && categoryProducts.map((item,index)=>{
          const options = {
            loop: true,
            margin: 10,
            nav: true,
            dots: false,
            autoPlay:false,
            responsive: {
              0: {
                items: 2,
              },
              600: {
                items: 3,
              },
              1000: {
                items: 4,
              },
              1200: {
                items: 5,
              },
            },
          };
          return (
              <section className="product-items-slider section-padding" key={index}>
                {item.products.length > 0 &&
                <div className="container">
                  <div className="section-header">
                    <h5 className="heading-design-h5 d-flex flex-row justify-content-between">
                      <h5>
                        {item.title}{" "}

                      </h5>
                      <a className="float-right text-secondary" href="#">
                        View All
                      </a>
                    </h5>
                  </div>

                  <OwlCarousel
                      className="owl-theme owl-carousel owl-carousel-featured"
                      {...options}
                  >
                    {item.products &&
                    item.products.length > 0 &&
                    item.products.map((item1, index1) => {
                      return (
                          <div className="item" key={index1}>
                            <ProductItem item={item1} setting={setting} />
                          </div>
                      );
                    })}
                  </OwlCarousel>
                </div>}
              </section>
          );
        })
      }

      </>
  );
}

export default TopCategoryProduct;
