import {constants} from '../constants';

const initialState = {
    isLoading: false,
    cart:{},
    error:undefined
};

export function CartReducer(state = initialState, action) {
  switch (action.type) {
    case constants.CART_REQUEST:
      return {
        ...state,
        isLoading: true,
        cart: action.payload,
      };
    case constants.CART_SUCCESS:
      return {
        ...state,
        isLoading: false,
        cart: action.payload,
        error:undefined
      };
    case constants.CART_FAILURE:
      return {
        ...state,
        isLoading: false,
        error:action.error
      };

      case constants.ADD_TO_CART_REQUEST:
        return {
          ...state,
          isLoading: true,
          cart: action.payload,
        };
      case constants.ADD_TO_CART_SUCCESS:
        return {
          ...state,
          isLoading: false,
          cart: action.payload,
          error:undefined
        };
      case constants.ADD_TO_CART_FAILURE:
        return {
          ...state,
          isLoading: false,
          error:action.error
        };

    default:
      return state;
  }
}
