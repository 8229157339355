import React, { useEffect, useState } from "react";
import { Badge, Accordion, Form } from "react-bootstrap";
import ShopImage from "../../assets/images/shop.png";
import MultiRangeSlider from "../../components/MultiRangeSlider";
import { useParams } from "react-router-dom";

import { connect } from "react-redux";
import { productData } from "../../redux/actions/ProductAction";
import Loader from "../../components/Loader";
import LocalStorage from "../../utils/LocalStorage";
import ProductItem from "../../components/ProductItem";

import InfiniteScroll from "react-infinite-scroll-component";
import {
  getProductsByCategory,
  getProductsBySubCategory,
} from "../../axios/ServerRequest";
import { Helmet } from "react-helmet";
import { BASE_URL } from "../../axios/API";

function Products(props) {
  const [page, setPage] = useState(1);
  const [end, setEnd] = useState(false);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [category, setCategory] = useState(null);
  const [subCategory, setSubCategory] = useState(null);
  const params = useParams();
  const setting = LocalStorage.getSetting();

  useEffect(() => {
    fetchData(page);
  }, []);

  const fetchData = (pageCount) => {
    if (params.cat_slug && params.sub_cat_slug) {
      getProductsBySubCategory(params.sub_cat_slug, pageCount)
        .then((response) => {
          if (pageCount === 1) {
            setData(response.data.products.data);
            setCategory(response.data.category);
            setSubCategory(response.data.subCategory);
            setPage(page + 1);
          } else {
            setData([...data, ...response.data.products.data]);
            setPage(page + 1);
          }
          if (
            response.data.products &&
            response.data.products.current_page ===
              response.data.products.last_page
          ) {
            setEnd(true);
          }

          setLoading(false);
        })
        .catch((error) => {
          console.log(error);
          setLoading(false);
        });
    } else {
      getProductsByCategory(params.cat_slug, pageCount)
        .then((response) => {
          if (pageCount === 1) {
            setData(response.data.products.data);
            setCategory(response.data.category);
            setPage(page + 1);
          } else {
            setData([...data, ...response.data.products.data]);
            setPage(page + 1);
          }

          if (
            response.data.products &&
            response.data.products.current_page ===
              response.data.products.last_page
          ) {
            setEnd(true);
          }

          setLoading(false);
        })
        .catch((error) => {
          console.log(error);
          setLoading(false);
        });
    }
    setPage(pageCount);
  };

  const fetchMoreData = () => {
    fetchData(page + 1);
  };

  return (
    <section className="shop-list section-padding">
      {data && data.length > 0 ? (
        <div className="container">
          <Helmet>
            <meta charSet="utf-8" />
            <title>{category.title}</title>
            <link rel="canonical" href={window.location.href} />
            <meta
              name="description"
              content={setting.short_des}
              data-react-helmet="true"
            />
            <meta
              name="keywords"
              content={setting.meta_tags}
              data-react-helmet="true"
            />
            <meta
              name="image"
              content={BASE_URL + setting.logo}
              data-react-helmet="true"
            />

            <meta
              property="og:title"
              content={category.title}
              data-react-helmet="true"
            />

            <meta
              property="og:url"
              content={window.location.href}
              data-react-helmet="true"
            />
            <meta
              property="og:description"
              content={setting.short_des}
              data-react-helmet="true"
            />
            <meta
              property="og:type"
              content="website"
              data-react-helmet="true"
            />
            <meta
              name="og:image"
              content={BASE_URL + setting.logo}
              data-react-helmet="true"
            />
            <meta
              name="twitter:card"
              content="summary_large_image"
              data-react-helmet="true"
            />
            <meta
              name="twitter:creator"
              content="@frontendsourcecode"
              data-react-helmet="true"
            />
            <meta
              name="twitter:title"
              content={category.title}
              data-react-helmet="true"
            />
            <meta
              name="twitter:description"
              content={setting.short_des}
              data-react-helmet="true"
            />
            <meta
              name="twitter:image"
              content={BASE_URL + setting.logo}
              data-react-helmet="true"
            />
          </Helmet>
          <div className="row">
            <div className="col-md-3">
              <div className="shop-filters">
                <Accordion defaultActiveKey={"0"}>
                  <Accordion.Item eventKey="0">
                    <Accordion.Header>Category</Accordion.Header>
                    <Accordion.Body>
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          value=""
                          id="flexCheckDefault"
                        />
                        <label
                          className="form-check-label"
                          for="flexCheckDefault"
                        >
                          Fresh & Herbs
                        </label>
                      </div>
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          value=""
                          id="flexCheckDefault"
                        />
                        <label
                          className="form-check-label"
                          for="flexCheckDefault"
                        >
                          Seasonal Fruits
                        </label>
                      </div>
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          value=""
                          id="flexCheckDefault"
                        />
                        <label
                          className="form-check-label"
                          for="flexCheckDefault"
                        >
                          Imported Fruits
                        </label>
                      </div>
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          value=""
                          id="flexCheckDefault"
                        />
                        <label
                          className="form-check-label"
                          for="flexCheckDefault"
                        >
                          Citrus
                        </label>
                      </div>
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          value=""
                          id="flexCheckDefault"
                        />
                        <label
                          className="form-check-label"
                          for="flexCheckDefault"
                        >
                          Cut Fresh & Herbs
                        </label>
                      </div>

                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          value=""
                          id="flexCheckDefault"
                        />
                        <label
                          className="form-check-label"
                          for="flexCheckDefault"
                        >
                          Seasonal Fruits
                        </label>
                      </div>
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          value=""
                          id="flexCheckDefault"
                        />
                        <label
                          className="form-check-label"
                          for="flexCheckDefault"
                        >
                          Fresh & Herbs
                        </label>
                      </div>

                      <button
                        type="button"
                        className="btn btn-secondary btn-sm"
                      >
                        Apply
                      </button>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="1">
                    <Accordion.Header>Price</Accordion.Header>
                    <Accordion.Body>
                      <div>
                        <MultiRangeSlider
                          min={0}
                          max={1000}
                          onChange={({ min, max }) =>
                            console.log(`min = ${min}, max = ${max}`)
                          }
                        />
                        <button
                          type="button"
                          className="btn btn-secondary btn-sm"
                        >
                          Apply
                        </button>
                      </div>
                    </Accordion.Body>
                  </Accordion.Item>

                  <Accordion.Item eventKey="2">
                    <Accordion.Header>Brand</Accordion.Header>
                    <Accordion.Body>
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          value=""
                          id="flexCheckDefault"
                        />
                        <label
                          className="form-check-label"
                          for="flexCheckDefault"
                        >
                          Fresh & Herbs
                        </label>
                      </div>
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          value=""
                          id="flexCheckDefault"
                        />
                        <label
                          className="form-check-label"
                          for="flexCheckDefault"
                        >
                          Seasonal Fruits
                        </label>
                      </div>
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          value=""
                          id="flexCheckDefault"
                        />
                        <label
                          className="form-check-label"
                          for="flexCheckDefault"
                        >
                          Imported Fruits
                        </label>
                      </div>
                      <div className="form-check">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          value=""
                          id="flexCheckDefault"
                        />
                        <label
                          className="form-check-label"
                          for="flexCheckDefault"
                        >
                          Citrus
                        </label>
                      </div>

                      <button
                        type="button"
                        className="btn btn-secondary btn-sm"
                      >
                        Apply
                      </button>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </div>
              <div className="left-ad mt-4">
                <img
                  className="img-fluid"
                  src="http://via.placeholder.com/254x557"
                  alt=""
                />
              </div>
            </div>
            <div className="col-md-9">
              <a href="#">
                <img
                  className="img-fluid mb-3"
                  src={ShopImage}
                  alt=""
                  style={{ maxHeight: 70, objectFit: "cover", width: "100%" }}
                />
              </a>
              <div className="shop-head">
                <a href="/">
                  <span className="mdi mdi-home"></span> Home
                </a>{" "}
                <span className="mdi mdi-chevron-right"></span>{" "}
                {params.sub_cat_slug && category ? (
                  <a href={`/shop/${params.cat_slug}`}>{category.title}</a>
                ) : (
                  <span>{category.title}</span>
                )}
              </div>
              <div className="d-flex justify-content-between align-items-center">
                {params.sub_cat_slug && subCategory ? (
                  <div>
                    <h5 className="mb-3">{subCategory.title}</h5>
                  </div>
                ) : (
                  <div>
                    <h5 className="mb-3">{category.title}</h5>
                  </div>
                )}
                <Form.Select size="sm" style={{ maxWidth: 200 }}>
                  <option>Price(Low to High)</option>
                  <option>Price(High to Low)</option>
                  <option>Discount(Low to High)</option>
                  <option>Discount(High to Low)</option>
                </Form.Select>
              </div>
              <InfiniteScroll
                dataLength={data.length / 3}
                next={fetchMoreData}
                hasMore={!end}
                loader={<p>Loading...</p>}
              >
                <div className="row g-0">
                  {data.map((item, index) => {
                    return (
                      <div className="col-lg-4 col-md-6" key={index}>
                        <ProductItem item={item} setting={setting} />
                      </div>
                    );
                  })}
                </div>
              </InfiniteScroll>
            </div>
          </div>
        </div>
      ) : null}
      <Loader loading={loading} />
    </section>
  );
}

export default Products;
