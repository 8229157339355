import React from "react";
import { Badge } from "react-bootstrap";
import OwlCarousel from "react-owl-carousel";
import ProductItem from "../../components/ProductItem";

function ProductSlider(props) {
  const { products, setting } = props;

  const options = {
    loop: true,
    margin: 10,
    autoplay: true,
    nav: true,
    dots: false,
    responsive: {
      0: {
        items: 2,
      },
      600: {
        items: 3,
      },
      1000: {
        items: 4,
      },
      1200: {
        items: 5,
      },
    },
  };
  return (
    <section className="product-items-slider section-padding">
      <div className="container">
        <div className="section-header">
          <h5 className="heading-design-h5 d-flex flex-row justify-content-between">
            <h5>
              {props.title}{" "}
              {props.subTitle && props.subTitle.length > 0 && (
                <Badge bg="primary"> {props.subTitle}</Badge>
              )}
            </h5>
            <a className="float-right text-secondary" href="#">
              View All
            </a>
          </h5>
        </div>

        <OwlCarousel
          className="owl-theme owl-carousel owl-carousel-featured"
          {...options}
        >
          {products &&
            products.length > 0 &&
            products.map((item, index) => {
              return (
                <div className="item" key={index}>
                  <ProductItem item={item} setting={setting} />
                </div>
              );
            })}
        </OwlCarousel>
      </div>
    </section>
  );
}

export default ProductSlider;
