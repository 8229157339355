import React, {useEffect} from "react";
import { Helmet } from "react-helmet";
import {connect} from "react-redux";
import { BASE_URL } from "../axios/API";
import {cartData} from "../redux/actions/CartAction";
import LocalStorage from "../utils/LocalStorage";

function ThankYou(props) {
  const setting = LocalStorage.getSetting();

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = () => {
        const user = LocalStorage.getUserDetails();

        if (user !== null) {
            props.cartData();
        }
    };
  return (
    <div
      className="container"
      style={{
        width: 800,
        margin: "auto",
        padding: 20,
        backgroundColor: "#fff",
        borderRadius: 10,
        textAlign: "center",
      }}
    >
      <Helmet>
        <meta charSet="utf-8" />
        <title>{setting.title} | ThankYou</title>
        <link rel="canonical" href={window.location.href} />
        <meta
          name="description"
          content={setting.short_des}
          data-react-helmet="true"
        />
        <meta
          name="keywords"
          content={setting.meta_tags}
          data-react-helmet="true"
        />
        <meta
          name="image"
          content={BASE_URL + setting.logo}
          data-react-helmet="true"
        />

        <meta
          property="og:title"
          content={setting.title}
          data-react-helmet="true"
        />

        <meta
          property="og:url"
          content={window.location.href}
          data-react-helmet="true"
        />
        <meta
          property="og:description"
          content={setting.short_des}
          data-react-helmet="true"
        />
        <meta property="og:type" content="website" data-react-helmet="true" />
        <meta
          name="og:image"
          content={BASE_URL + setting.logo}
          data-react-helmet="true"
        />
        <meta
          name="twitter:card"
          content="summary_large_image"
          data-react-helmet="true"
        />
        <meta
          name="twitter:creator"
          content="@frontendsourcecode"
          data-react-helmet="true"
        />
        <meta
          name="twitter:title"
          content={setting.title}
          data-react-helmet="true"
        />
        <meta
          name="twitter:description"
          content={setting.short_des}
          data-react-helmet="true"
        />
        <meta
          name="twitter:image"
          content={BASE_URL + setting.logo}
          data-react-helmet="true"
        />
      </Helmet>
      <img
        style={{ marginTop: 80, width: 150 }}
        src="https://images.squarespace-cdn.com/content/v1/5806ec038419c29b9187cc2b/1535941471036-50OPAXHY2CBPKTZQ2RPL/ke17ZwdGBToddI8pDm48kL2RXeg1pfhKEUOVWeEY6-AUqsxRUqqbr1mOJYKfIPR7LoDQ9mXPOjoJoqy81S2I8N_N4V1vUb5AoIIIbLZhVYxCRW4BPu10St3TBAUQYVKcHqpiYMSyvrdP-0SAQ36MiUPx3rOM05vbnHz3QNpBg_-bLq9HngwVJFHVrh8wl9VZ/hooray.jpg"
        className="gif"
      />
      <div className="msg" style={{ margin: "80px 0" }}>
        <h2>Your order is complete</h2>
        <p>You will receive a confirmation email with order details.</p>
      </div>
      <a href="/"
        style={{
          marginBottom: 80,
          backgroundColor: "#ffff",
          border: "3px solid #f87050",
          borderRadius: 50,
          color: "#f87050",
          padding: "15px 32px",
          textDecoration: "none",
          display: "inline-block",
          fontSize: 16,
          cursor: "pointer",
          textAlign: "center",
        }}

      >
        Explore more Products
      </a>
    </div>
  );
}

const mapStoreToProps = (state) => ({
    isLoading: state.CartReducer.isLoading,
    data: state.CartReducer.cart,
    error: state.CartReducer.error,
});
const mapDispatchToProps = (dispatch) => ({
    cartData: () => dispatch(cartData()),
});

export default connect(mapStoreToProps, mapDispatchToProps)(ThankYou);
