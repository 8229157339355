import React from "react";
import OwlCarousel from "react-owl-carousel";
import { BASE_URL } from "../../axios/API";

function PromotionSlider({ promotions }) {
  const options = {
    loop: true,
    margin: 10,
    autoplay: false,
    nav: true,
    dots: false,
    responsive: {
      0: {
        items: 1,
      },
      600: {
        items: 2,
      },
      1000: {
        items: 3,
      },
      1200: {
        items: 3,
      },
    },
  };
  return (
    <section className="product-items-slider section-padding">
      <div className="container">
        <OwlCarousel
          className="owl-theme owl-carousel owl-carousel-featured"
          {...options}
        >
          {promotions &&
            promotions.length > 0 &&
            promotions.map((item, index) => {
              return (
                <div className="item" key={index}>
                  <a href="#">
                    <img
                      src={BASE_URL + item.photo}
                      className="img-fluid mx-auto rounded"
                      alt={BASE_URL + item.photo}
                    />
                  </a>
                </div>
              );
            })}
        </OwlCarousel>
      </div>
    </section>
  );
}

export default PromotionSlider;
