import {getHomeData, topCategoryWithProduct} from '../../axios/ServerRequest';
import LocalStorage from '../../utils/LocalStorage';
import {constants} from '../constants';

export function isLoading(bool) {
  return {
    type: constants.HOME_REQUEST,
    isLoading: bool,
  };
}

export function homeSuccess(data) {
  return {
    type: constants.HOME_SUCCESS,
    data,
  };
}

export function topCategorySuccess(data) {
  return {
    type: constants.TOP_CATEGORY_SUCCESS,
    data,
  };
}

export function homeFailed(error) {
  return {
    type: constants.HOME_FAILURE,
    error,
  };
}

export const homeData = () => dispatch => {
  dispatch(isLoading(true));
  return getHomeData()
    .then(res => {
        LocalStorage.setHome(res.data);
        dispatch(homeSuccess(res.data))
    })
    .catch(error => {
        dispatch(homeFailed(error.response.message))
    });
};

export const topCategoryData = () => dispatch => {
  dispatch(isLoading(true));
  return topCategoryWithProduct()
    .then(res => {
        dispatch(topCategorySuccess(res.data))
    })
    .catch(error => {
        dispatch(homeFailed(error.response.message))
    });
};
