import React from "react";
import ProductItem from "../../components/ProductItem";
import ProductImage1 from "../../assets/images/item/1.jpg";
import ProductImage2 from "../../assets/images/item/2.jpg";
import ProductImage3 from "../../assets/images/item/3.jpg";
import ProductImage4 from "../../assets/images/item/4.jpg";


function WishList() {
  return (
    <div className="card card-body account-right">
      <div className="widget">
        <div className="section-header">
          <h5 className="heading-design-h5">Wishlist</h5>
        </div>
        <div className="row g-0">
            <div className="col-md-6">
                <ProductItem image={ProductImage1}/>
            </div>
            <div className="col-md-6">
                <ProductItem image={ProductImage2}/>
            </div>
            <div className="col-md-6">
                <ProductItem image={ProductImage3}/>
            </div>
            <div className="col-md-6">
                <ProductItem image={ProductImage4}/>
            </div>

        </div>
      </div>
    </div>
  );
}

export default WishList;
