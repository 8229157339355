import {constants} from '../constants';

const initialState = {
  isLoading: false,
  brandData: {},
  error: undefined,
};

export function BrandReducer(state = initialState, action) {
  switch (action.type) {
    case constants.BRAND_REQUEST:
      return {
        ...state,
        isLoading: true,
        brandData: action.data,
      };
    case constants.BRAND_SUCCESS:
      return {
        ...state,
        isLoading: false,
        brandData: action.data,
        error: undefined,
      };
    case constants.BRAND_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };

    default:
      return state;
  }
}
