import {getAllCategories} from '../../axios/ServerRequest';
import {constants} from '../constants';

export function isLoading(bool) {
  return {
    type: constants.CATEGORY_REQUEST,
    isLoading: bool,
  };
}

export function categorySuccess(categories) {
  return {
    type: constants.CATEGORY_SUCCESS,
    categories,
  };
}

export function categoryFailed(error) {
  return {
    type: constants.CATEGORY_FAILURE,
    error,
  };
}

export const allCategoryData = () => dispatch => {
  dispatch(isLoading(true));
  return getAllCategories()
    .then(res => {
        dispatch(categorySuccess(res.data))
    })
    .catch(error => {
        dispatch(categoryFailed(error.response.message))
    });
};
