import {addAddress, getAddresses, updateAddress} from '../../axios/ServerRequest';
import {constants} from '../constants';

export function isLoading(bool) {
  return {
    type: constants.ADDRESS_REQUEST,
    isLoading: bool,
  };
}

export function addressSuccess(address) {
  return {
    type: constants.ADDRESS_SUCCESS,
    address,
  };
}

export function addressFailed(error) {
  return {
    type: constants.ADDRESS_FAILURE,
    error,
  };
}

export const addressData = () => dispatch => {
  dispatch(isLoading(true));
  return getAddresses()
    .then(res => {
      dispatch(addressSuccess(res.data));
    })
    .catch(error => {
      dispatch(addressFailed(error.response.message));
    });
};


export const addAddressData = (data,option) => dispatch => {
  dispatch(isLoading(true));
  if(option==="add"){
    return addAddress(data)
    .then(res => {
      dispatch(addressSuccess(res.data));
    })
    .catch(error => {
      dispatch(addressFailed(error.response.message));
    });
  }else{
    return updateAddress(data,data.id)
    .then(res => {
      dispatch(addressSuccess(res.data));
    })
    .catch(error => {
      dispatch(addressFailed(error.response.message));
    });
  }
 
};

