import {constants} from '../constants';

const initialState = {
    isLoading: false,
    settingData:{},
    error:undefined
};

export function SettingReducer(state = initialState, action) {
  switch (action.type) {
    case constants.SETTING_REQUEST:
      return {
        ...state,
        isLoading: true,
        settingData: action.data,
      };
    case constants.SETTING_SUCCESS:
      return {
        ...state,
        isLoading: false,
        settingData: action.data,
        error:undefined
      };
    case constants.SETTING_FAILURE:
      return {
        ...state,
        isLoading: false,
        error:action.error
      };

    default:
      return state;
  }
}
