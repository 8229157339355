import React, {useState} from "react";
import LocalStorage from "../../utils/LocalStorage";
import {updateProfile} from "../../axios/ServerRequest";
import data from "bootstrap/js/src/dom/data";
import {BASE_URL} from "../../axios/API";
import axios from "axios";

function ProfileDetails(props) {
  const user = LocalStorage.getUserDetails();
   const [name,setName]  = useState(user.name);
   const [phone,setPhone]  = useState(user.phone);
   const [email,setEmail]  = useState(user.email);
   const [photo,setPhoto]  = useState(user.photo);

   const onSave=async ()=>{
     if(name.length<3){
       return;
     }else{
       const formData = new FormData();
       formData.append("name",name);
       formData.append("photo",photo);
       axios.post(BASE_URL+"/api/v1/user/update", formData, {
             headers: {
               'Authorization': LocalStorage.getApiKey()
             }
           }
       ).then((response)=>{
           LocalStorage.setUserDetails(response.data.user);
           props.profileUpdate();
       }).catch((error)=>{
        console.log(error);
      })

     }
   }

  return (
    <div className="card card-body account-right">
      <div className="widget">
        <div className="section-header">
          <h5 className="heading-design-h5">My Profile</h5>
        </div>
        <form>
          <div className="row">

              <div className="form-group">
                <label className="control-label">
                  Name <span className="required">*</span>
                </label>
                <input
                  className="form-control border-form-control"
                  value={name}
                  placeholder="Name"
                  type="text"
                  onChange={(e)=>setName(e.target.value)}
                />
              </div>

          </div>
          <div className="row">
            <div className="col-sm-6">
              <div className="form-group">
                <label className="control-label">
                  Phone <span className="required">*</span>
                </label>
                <input
                  className="form-control border-form-control"
                  value={phone}
                  placeholder="Phone"
                  type="number"
                  disabled
                />
              </div>
            </div>
            <div className="col-sm-6">
              <div className="form-group">
                <label className="control-label">
                  Email Address <span className="required">*</span>
                </label>
                <input

                  className="form-control border-form-control "
                  value={email}
                  placeholder="phone"
                  disabled
                  type="text"
                />
              </div>
            </div>
          </div>
         
          
          <div className="row">
            <div className="form-group">
              <label className="control-label">
                Profile Image
              </label>
              <input
                  type="file"
                  className="form-control border-form-control "
                  placeholder="choose image"
                  onChange={(e)=>setPhoto(e.target.files[0])}
              />
            </div>
          </div>
          <div className="row mt-3">
            <div className="col-sm-12 text-right">

              <button type="button" className="btn btn-success btn-lg ms-3" onClick={onSave}>
                {" "}
                Save Changes{" "}
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}

export default ProfileDetails;
