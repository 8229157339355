import {getProductDetails} from '../../axios/ServerRequest';
import {constants} from '../constants';

export function isLoading(bool) {
  return {
    type: constants.PRODUCT_DETAILS_REQUEST,
    isLoading: bool,
  };
}

export function productDetailsSuccess(product) {
  return {
    type: constants.PRODUCT_DETAILS_SUCCESS,
    product,
  };
}

export function productDetailsFailed(error) {
  return {
    type: constants.PRODUCT_DETAILS_FAILURE,
    error,
  };
}

export const productDetails = (slug) => dispatch => {
  dispatch(isLoading(true));
  
    return getProductDetails(slug)
    .then(res => {
        dispatch(productDetailsSuccess(res.data))
    })
    .catch(error => {
        dispatch(productDetailsFailed(error.response.message))
    });
 
};
