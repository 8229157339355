import React, {useEffect, useState} from "react";
import {cancelOrder, getOrderDetails, getOrders} from "../../axios/ServerRequest";
import InfiniteScroll from "react-infinite-scroll-component";
import {BASE_URL} from "../../axios/API";
import toast, { Toaster } from 'react-hot-toast';
import LocalStorage from "../../utils/LocalStorage";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

function Orders(props) {
  const [page, setPage] = useState(1);
  const [end, setEnd] = useState(false);
  const [data, setData] = useState([]);
  const [order, setOrder] = useState(null);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const setting = LocalStorage.getSetting();
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  useEffect(()=>{
    fetchOrders(page);
  },[])

  const fetchOrders=(pageCount)=>{
        getOrders(pageCount).then((response)=>{
          if (pageCount === 1) {
            setData(response.data.orders.data);
            setPage(page + 1);
          } else {
            setData([...data, ...response.data.orders.data]);
            setPage(page + 1);
          }
          if (
              response.data.orders &&
              response.data.orders.current_page ===
              response.data.orders.last_page
          ) {
            setEnd(true);
          }


        }).catch((error)=>{
          console.log(error);
        })
  }

  const fetchMoreData = () => {
    fetchOrders(page + 1);
  };

  const onCancel=async (id)=>{
     await cancelOrder(id).then((response)=>{
       if(response.status===200){
         toast.success("Order Cancelled successfully.", {
           duration: 4000,
           position: 'top-right',
         })
         fetchOrders(1)
       }else{
         toast.error("Order Cannot be Cancelled.", {
           duration: 4000,
           position: 'top-right',
         })
       }
     }).catch((error)=>{
       console.log(error);
       toast.error("Order Cannot be Cancelled.", {
         duration: 4000,
         position: 'top-right',
       })
     })
  }

  const showOrderDetails=async (id)=>{
     handleShow();
     await getOrderDetails(id).then((response)=>{
         if(response.status===200){
           let data = response.data.orderData;
           setOrder(data);
         }
     }).catch((error)=>{
       toast.error("Order Not Found", {
         duration: 4000,
         position: 'top-right',
       })
     });
  }

  return (
    <div className="card card-body account-right">
      <div className="widget">
        <div className="section-header">
          <h5 className="heading-design-h5">Order List</h5>
        </div>
        <div className="order-list-tabel-main table-responsive">
          <InfiniteScroll
              dataLength={data.length / 1}
              next={fetchMoreData}
              hasMore={!end}
              loader={<p>Loading...</p>}
          >
          <table
            className="datatabel table table-striped table-bordered order-list-tabel"
            width="100%"
            cellspacing="0"
          >
            <thead>
              <tr>
                <th>Order #</th>
                <th>Date Purchased</th>
                <th>Status</th>
                <th>Total</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
            {data && data.map((item,index)=>{
              return( <tr>
                <td>#{item.order_number}</td>
                <td>{item.created_at}</td>
                <td>
                  {item.status}
                </td>
                <td>{setting?setting.currency:""}{item.total_amount}</td>
                <td>
                  <button
                      data-toggle="tooltip"
                      data-placement="top"
                      title="View Order"
                      data-original-title="View Detail"
                      className="btn btn-primary btn-sm"
                      onClick={()=> {
                        setOrder(null);
                        setSelectedOrder(item);
                        showOrderDetails(item.id);
                      }}
                    >
                    <i className="mdi mdi-eye"></i>
                  </button>

                  <a
                      data-toggle="tooltip"
                      data-placement="top"
                      title="Download invoice"
                      href={`${BASE_URL}/order/pdf/${item.id}`}
                      data-original-title="View Detail"
                      className="btn btn-info btn-sm"
                      target="_blank"
                  >
                    <i className="mdi mdi-download"></i>
                  </a>
                  {item.status === 'new' ? (
                  <button
                      data-toggle="tooltip"
                      data-placement="top"
                      title="Cancel Order"
                      href="#"
                      data-original-title="View Detail"
                      className="btn btn-danger btn-sm m-1"
                      onClick={()=>onCancel(item.id)}
                  >
                    <i className="mdi mdi-close"></i>
                  </button>):null}
                </td>
              </tr>);
            })}


            </tbody>
          </table>
          </InfiniteScroll>
        </div>
      </div>
      <Toaster />
      <Modal show={show} onHide={handleClose} size={"lg"}>
        <Modal.Header closeButton>
          <Modal.Title>#{selectedOrder?selectedOrder.order_number:""}</Modal.Title>
        </Modal.Header>
        {selectedOrder && order?
        <Modal.Body>
           <div className="row">
             <div className="col-md-6">
               <div><strong>Shop Address</strong></div>
              <div>{setting.phone}</div>
              <div>{setting.email}</div>
              <div>{setting.address}</div>
             </div>
             <div className="col-md-6">
               <div className="float-end"><strong>Delivery Address</strong></div><br/>
               <div className="text-end">{selectedOrder.first_name} {selectedOrder.last_name?selectedOrder.last_name:""}</div>
               <div className="text-end">{selectedOrder.phone}</div>
               <div className="text-end">{selectedOrder.email}</div>
               <div className="text-end">{selectedOrder.address1},{selectedOrder.city}-{selectedOrder.post_code}</div>
             </div>
           </div>
          <table className="table table-borderless mt-3 mb-3">
            <thead>
            <tr>
              <th scope="col">#</th>
              <th scope="col">image</th>
              <th scope="col">Title</th>
              <th scope="col">Attribute</th>
              <th scope="col">Price</th>
              <th scope="col">Quantity</th>
              <th scope="col">amount</th>
            </tr>
            </thead>
            <tbody>
            {order.map((item,index)=>{
              return <tr>
                <th scope="row">{index}</th>
                <td><img src={BASE_URL+item.photo.split(",")[0]} style={{maxWidth:50}}/></td>
                <td>{item.title}</td>
                <td>{item.attribute}{item.unit}</td>
                <td>{setting.currency}{item.price}</td>
                <td>{item.quantity}</td>
                <td>{setting.currency}{item.amount}</td>
              </tr>
            })}
             <tr>
               <td colSpan={7}>
                 <hr/>
               </td>
             </tr>
            <tr>
              <td colSpan={4}></td>
              <td colSpan={2}>
                <strong>Sub-Total :</strong>
              </td>
              <td>{setting.currency}{selectedOrder.sub_total}</td>
            </tr>
            <tr>
              <td colSpan={4}></td>
              <td colSpan={2}>
                <strong>Shipping Charge :</strong>
              </td>
              <td>{setting.currency}{selectedOrder.shipping.price}</td>
            </tr>
            <tr>
              <td colSpan={4}></td>
              <td colSpan={2}>
                <strong>Discount :</strong>
              </td>
              <td>{setting.currency}{selectedOrder.discount?selectedOrder.discount:0.0}</td>
            </tr>
            <tr>
              <td colSpan={7}>
                <hr/>
              </td>
            </tr>
             <tr>
              <td colSpan={4}></td>
              <td colSpan={2}>
                <strong>Total Amount :</strong>
              </td>
              <td>{setting.currency}{selectedOrder.total_amount}</td>
            </tr>

            </tbody>
          </table>
        </Modal.Body>:null}

      </Modal>
    </div>
  );
}

export default Orders;
