import React, { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import { getSettingData } from "../axios/ServerRequest";
import FloatingCart from "../components/FloatingCart";
import Footer from "../components/Footer";
import NavBar from "../components/NavBar";
import TopNavBar from "../components/TopNavBar";
import LocalStorage from "../utils/LocalStorage";

function BaseLayout(props) {
  const [setting, setSetting] = useState(null);
  const [show, setShow] = useState(false);

  useEffect(() => {
    const settingData = LocalStorage.getSetting();
    setSetting(settingData);
  }, []);

  return (
    <div className="main-wrapper position-relative">
      <div className="main-container">
        <TopNavBar setting={setting} show={show} setShow={setShow} />
        <NavBar setting={setting} show={show} />
        <section className="main-body">
          <Outlet />
        </section>
        <Footer setting={setting} />
      </div>
      <FloatingCart />
    </div>
  );
}

export default BaseLayout;
