import React, { useState } from "react";
import ProfileMenu from "../../components/ProfileMenu";
import Address from "./Address";
import Orders from "./Orders";
import ProfileDetails from "./ProfileDetails";
import WishList from "./WishList";
import LocalStorage from "../../utils/LocalStorage";
import { Helmet } from "react-helmet";
import { BASE_URL } from "../../axios/API";

function Profile() {
  const [user, setUser] = useState(LocalStorage.getUserDetails());
  const [selectedOption, setSelectedOption] = useState(0);
  const setting = LocalStorage.getSetting();

  const onSelectOption = (params) => {
    setSelectedOption(params);
  };

  const profileUpdate = () => {
    setUser(LocalStorage.getUserDetails());
  };
  return (
    <section className="account-page section-padding">
      <Helmet>
        <meta charSet="utf-8" />
        <title>{user.name}</title>
        <link rel="canonical" href={window.location.href} />
        <meta
          name="description"
          content={setting.short_des}
          data-react-helmet="true"
        />
        <meta
          name="keywords"
          content={setting.meta_tags}
          data-react-helmet="true"
        />
        <meta
          name="image"
          content={BASE_URL + setting.logo}
          data-react-helmet="true"
        />

        <meta
          property="og:title"
          content={user.name}
          data-react-helmet="true"
        />

        <meta
          property="og:url"
          content={window.location.href}
          data-react-helmet="true"
        />
        <meta
          property="og:description"
          content={setting.short_des}
          data-react-helmet="true"
        />
        <meta property="og:type" content="website" data-react-helmet="true" />
        <meta
          name="og:image"
          content={BASE_URL + setting.logo}
          data-react-helmet="true"
        />
        <meta
          name="twitter:card"
          content="summary_large_image"
          data-react-helmet="true"
        />
        <meta
          name="twitter:creator"
          content="@frontendsourcecode"
          data-react-helmet="true"
        />
        <meta
          name="twitter:title"
          content={user.name}
          data-react-helmet="true"
        />
        <meta
          name="twitter:description"
          content={setting.short_des}
          data-react-helmet="true"
        />
        <meta
          name="twitter:image"
          content={BASE_URL + setting.logo}
          data-react-helmet="true"
        />
      </Helmet>

      <div className="container">
        <div className="row">
          <div className="col-lg-9 mx-auto">
            <div className="row no-gutters">
              <div className="col-md-4">
                {user ? (
                  <ProfileMenu onSelectOption={onSelectOption} user={user} />
                ) : null}
              </div>
              <div className="col-md-8">
                {selectedOption === 0 ? (
                  <ProfileDetails profileUpdate={profileUpdate} />
                ) : null}
                {selectedOption === 1 ? <Address /> : null}
                {/*{selectedOption === 2 ? <WishList /> : null}*/}
                {selectedOption === 2 ? <Orders /> : null}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Profile;
