import React, {useEffect, useState} from "react";
import { connect } from "react-redux";
import { addAddressData, addressData } from "../../redux/actions/AddressAction";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import LocalStorage from "../../utils/LocalStorage";
import { confirmAlert } from "react-confirm-alert";
import {deleteAddress} from "../../axios/ServerRequest"; // Import

function Address(props) {
  const [show, setShow] = useState(false);

  const [id, setId] = useState(null);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [city, setCity] = useState("");
  const [pinCode, setPinCode] = useState("");
  const [address, setAddress] = useState("");

  const [firstNameError, setFirstNameError] = useState("");
  const [lastNameError, setLastNameError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [phoneError, setPhoneError] = useState("");
  const [cityError, setCityError] = useState("");
  const [pinCodeError, setPinCodeError] = useState("");
  const [addressError, setAddressError] = useState("");

  const setting = LocalStorage.getSetting();
  const userLocation = LocalStorage.getUserLocation();
  let locations = new Array();
  if (setting && setting.availability) {
    locations = setting.availability.split(",");
  }

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  useEffect(() => {
    props.addressData();
  }, []);
  let addresses = [];
  if(props.addresses && props.addresses.addresses && props.addresses.addresses.length>0) {
    addresses = props.addresses.addresses;
  }



  const saveAddress=()=>{
    setFirstNameError("");
    setPhoneError("");
    setEmailError("");
    setPinCodeError("");
    setCityError("");
    setAddressError("");
    if(firstName.length <3){
      setFirstNameError("Enter valid name.");
    }else if(phone.length<10){
      setPhoneError("Enter valid phone number.");
    }else if(email.length<10){
      setEmailError("Enter valid email address.");
    }else if(city.length===0){
      setCityError("select a valid city");
    }else if(pinCode.length<6){
      setPinCodeError("Enter valid pincode");
    }else if(address.length<10){
      setPinCodeError("Please provide the number and street.");
    }else{
      const data = {
        id:id,
        first_name:firstName,
        last_name:lastName,
        email:email,
        phone:phone,
        city:city,
        post_code:pinCode,
        address1:address,
        default_address:1,
      }
      if(id && id!==null){
        props.addAddress(data,"update");
      }else{
        props.addAddress(data,"add");
      }
      handleClose();
    }
  }

  const onEdit=(item)=>{
     setId(item.id);
     setFirstName(item.first_name);
     setLastName(item.last_name);
     setPhone(item.phone);
     setEmail(item.email);
     setAddress(item.address1);
     setCity(item.city);
     setPinCode(item.post_code);
     setFirstNameError("");
     setLastNameError("");
     setPhoneError("");
     setEmailError("");
     setAddressError("");
     setPinCodeError("");
     setCityError("");

     handleShow()
  }

  const onAddAddress=()=>{
    setId(null);
    setFirstName("");
    setLastName("");
    setPhone("");
    setEmail("");
    setAddress("");
    setCity("");
    setPinCode("");
    setFirstNameError("");
    setLastNameError("");
    setPhoneError("");
    setEmailError("");
    setAddressError("");
    setPinCodeError("");
    setCityError("");

    handleShow()
  }

  const onDelete = (id) => {
    confirmAlert({
      customUI: ({ onClose }) => {
        return (
            <div className="custom-ui">
              <h1>Are you sure?</h1>
              <p>You want to delete this data?</p>
              <button onClick={onClose}>No</button>
              <button
                  onClick={() => {
                    onDeleteAddress(id);
                    onClose();
                  }}
              >
                Yes, Delete it!
              </button>
            </div>
        );
      },
    });
  };

  const onDeleteAddress = async (id) => {
    await deleteAddress(id)
        .then((response) => {
          if (response.status === 204) {
            props.addressData();
          }
        })
        .catch((error) => {
          console.log(error);
        });
  };



  return (
    <div className="card card-body account-right">
      <div className="widget">
        <div className="section-header">
          <div className="d-flex flex-row justify-content-between align-content-center mb-3">
            <h5 className="heading-design-h5">Contact Address</h5>
            <Button variant="primary" onClick={onAddAddress}>+ Add Address</Button>
          </div>
        </div>

        {addresses.map((item,index)=>{
          return <div className="card mb-3" key={index} style={{border:"1px solid #e8e8e8"}}>
            <div className="card-body">
              <strong>{item.first_name} {item.last_name?item.last_name:""}</strong>
              <div>{item.phone}</div>
              <div>{item.email}</div>
              <div>{item.address1}</div>
              <div>{item.address2}</div>
              <div>{item.land_mark}</div>
              <div>{item.city}-{item.post_code}</div>
            </div>
            <div className="position-absolute top-0" style={{right:0}}>
              <button className="btn btn-sm btn-primary m-1" onClick={()=>onEdit(item)}> <i className="mdi mdi-pencil"/></button>
              <button className="btn btn-sm btn-danger m-1"  onClick={() => onDelete(item.id)}> <i className="mdi mdi-delete" /></button>

            </div>
          </div>

        })}

      </div>
      <Modal show={show} onHide={handleClose} animation={false}  size="lg"
             aria-labelledby="contained-modal-title-vcenter"
             centered>
        <Modal.Header closeButton>
          <Modal.Title>{id && id!==null?"Edit Address":"Add Address"}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form>
            <div className="row">
              <div className="col-sm-6">
                <div className="form-group">
                  <label className="control-label">
                    First Name <span className="required">*</span>
                  </label>
                  <input
                      className="form-control border-form-control"
                      value={firstName}
                      placeholder="Enter first name"
                      type="text"
                      onChange={(e)=>{setFirstName(e.target.value)}}
                      maxLength={20}
                  />
                  {firstNameError.length>0&&
                  <small className="text-danger">{firstNameError}</small>}
                </div>
              </div>
              <div className="col-sm-6">
                <div className="form-group">
                  <label className="control-label">
                    Last Name <span className="required">*</span>
                  </label>
                  <input
                      className="form-control border-form-control"
                      value={lastName}
                      placeholder="Enter last name"
                      type="text"
                      onChange={(e)=>{setLastName(e.target.value)}}

                  />
                  {lastNameError.length>0&&
                  <small className="text-danger">{lastNameError}</small>}
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-6">
                <div className="form-group">
                  <label className="control-label">
                    Phone <span className="required">*</span>
                  </label>
                  <input
                      className="form-control border-form-control"
                      value={phone}
                      placeholder="Enter phone number"
                      type="number"
                      onChange={(e)=>{setPhone(e.target.value)}}
                      maxLength={10}
                  />
                  {phoneError.length>0&&
                  <small className="text-danger">{phoneError}</small>}
                </div>
              </div>
              <div className="col-sm-6">
                <div className="form-group">
                  <label className="control-label">
                    Email Address <span className="required">*</span>
                  </label>
                  <input
                      className="form-control border-form-control "
                      value={email}
                      placeholder="Enter email address"
                      disabled=""
                      type="email"
                      maxLength={50}
                      onChange={(e)=>{setEmail(e.target.value)}}
                  />
                  {emailError.length>0&&
                  <small className="text-danger">{emailError}</small>}
                </div>
              </div>
            </div>
            <div className="row">

              <div className="col-sm-6">
                <div className="form-group">
                  <label className="control-label">
                    City <span className="required">*</span>
                  </label>
                  <select className="select2 form-control border-form-control" value={city} onChange={(e)=>setCity(e.target.value)}>
                    <option value="">Select City</option>
                    {locations &&
                    locations.map((item, index) => {
                      return (
                          <option
                              value={item}
                              key={index}
                              selected={userLocation === item ? true : false}
                          >
                            {item}
                          </option>
                      );
                    })}
                  </select>
                  {cityError.length>0&&
                  <small className="text-danger">{cityError}</small>}
                </div>
              </div>
              <div className="col-sm-6">
                <div className="form-group">
                  <label className="control-label">
                    Zip Code <span className="required">*</span>
                  </label>
                  <input
                      className="form-control border-form-control"
                      value={pinCode}
                      placeholder="Enter Pin Code"
                      maxLength={6}
                      type="number"
                      onChange={(e)=>{setPinCode(e.target.value)}}
                  />
                  {pinCodeError.length>0&&
                  <small className="text-danger">{pinCodeError}</small>}
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-sm-12">
                <div className="form-group">
                  <label className="control-label">
                    Shipping Address{" "}
                    <span className="required">*</span>
                  </label>
                  <textarea className="form-control border-form-control"  onChange={(e)=>{setAddress(e.target.value)}}>{address}</textarea>
                  <small className="text-danger">
                    {addressError}
                  </small>
                </div>
              </div>
            </div>
            <button
                    type="button"
                    className="btn btn-secondary mb-2 mt-2 btn-lg"
                    onClick={saveAddress} >
                  Save Address
                </button>

          </form>
        </Modal.Body>

      </Modal>
    </div>
  );
}

const mapStoreToProps = (state) => ({
  isLoading: state.AddressReducer.isLoading,
  addresses: state.AddressReducer.addresses,
  error: state.AddressReducer.error,
});
const mapDispatchToProps = (dispatch) => ({
  addAddress: (data, option) => dispatch(addAddressData(data, option)),
  addressData: () => dispatch(addressData()),
});

export default connect(mapStoreToProps, mapDispatchToProps)(Address);