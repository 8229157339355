export const constants = {
  REGISTER_REQUEST: 'USERS_REGISTER_REQUEST',
  REGISTER_SUCCESS: 'USERS_REGISTER_SUCCESS',
  REGISTER_FAILURE: 'USERS_REGISTER_FAILURE',

  LOGIN_REQUEST: 'USERS_LOGIN_REQUEST',
  LOGIN_SUCCESS: 'USERS_LOGIN_SUCCESS',
  LOGIN_FAILURE: 'USERS_LOGIN_FAILURE',

  LOGOUT: 'USERS_LOGOUT',

  DELETE_REQUEST: 'USERS_DELETE_REQUEST',
  DELETE_SUCCESS: 'USERS_DELETE_SUCCESS',
  DELETE_FAILURE: 'USERS_DELETE_FAILURE',

  SEND_OTP_REQUEST: 'SEND_OTP_REQUEST',
  SEND_OTP_SUCCESS: 'SEND_OTP_SUCCESS',
  SEND_OTP_FAILURE: 'SEND_OTP_FAILURE',

  OTP_VERIFICATION_REQUEST: 'OTP_VERIFICATION_REQUEST',
  OTP_VERIFICATION_SUCCESS: 'OTP_VERIFICATION_SUCCESS',
  OTP_VERIFICATION_FAILURE: 'OTP_VERIFICATION_FAILURE',

  FORGOT_PASSWORD_REQUEST: 'FORGOT_PASSWORD_REQUEST',
  FORGOT_PASSWORD_SUCCESS: 'FORGOT_PASSWORD_SUCCESS',
  RESET_PASSWORD_SUCCESS: 'RESET_PASSWORD_SUCCESS',
  FORGOT_PASSWORD_FAILURE: 'FORGOT_PASSWORD_FAILURE',

  HOME_REQUEST: 'HOME_REQUEST',
  HOME_SUCCESS: 'HOME_SUCCESS',
  HOME_FAILURE: 'HOME_FAILURE',

  TOP_CATEGORY_REQUEST: 'TOP_CATEGORY_REQUEST',
  TOP_CATEGORY_SUCCESS: 'TOP_CATEGORY_SUCCESS',
  TOP_CATEGORY_FAILURE: 'TOP_CATEGORY_FAILURE',

  SETTING_REQUEST: 'SETTING_REQUEST',
  SETTING_SUCCESS: 'SETTING_SUCCESS',
  SETTING_FAILURE: 'SETTING_FAILURE',

  BRAND_REQUEST: 'BRAND_REQUEST',
  BRAND_SUCCESS: 'BRAND_SUCCESS',
  BRAND_FAILURE: 'BRAND_FAILURE',

  CATEGORY_REQUEST: 'CATEGORY_REQUEST',
  CATEGORY_SUCCESS: 'CATEGORY_SUCCESS',
  CATEGORY_FAILURE: 'CATEGORY_FAILURE',

  PRODUCT_REQUEST: 'PRODUCT_REQUEST',
  PRODUCT_SUCCESS: 'PRODUCT_SUCCESS',
  PRODUCT_FAILURE: 'PRODUCT_FAILURE',

  PRODUCT_DETAILS_REQUEST: 'PRODUCT_DETAILS_REQUEST',
  PRODUCT_DETAILS_SUCCESS: 'PRODUCT_DETAILS_SUCCESS',
  PRODUCT_DETAILS_FAILURE: 'PRODUCT_DETAILS_FAILURE',

  CART_REQUEST: 'CART_REQUEST',
  CART_SUCCESS: 'CART_SUCCESS',
  CART_FAILURE: 'CART_FAILURE',

  ADD_TO_CART_REQUEST: 'ADD_TO_CART_REQUEST',
  ADD_TO_CART_SUCCESS: 'ADD_TO_CART_SUCCESS',
  ADD_TO_CART_FAILURE: 'ADD_TO_CART_FAILURE',

  WISHLIST_REQUEST: 'WISHLIST_REQUEST',
  WISHLIST_SUCCESS: 'WISHLIST_SUCCESS',
  WISHLIST_FAILURE: 'WISHLIST_FAILURE',

  ADDRESS_REQUEST: 'ADDRESS_REQUEST',
  ADDRESS_SUCCESS: 'ADDRESS_SUCCESS',
  ADDRESS_FAILURE: 'ADDRESS_FAILURE',

  ORDER_REQUEST: 'ORDER_REQUEST',
  ORDER_SUCCESS: 'ORDER_SUCCESS',
  ORDER_FAILURE: 'ORDER_FAILURE',

  SHIPPING_DETAILS_REQUEST: 'SHIPPING_DETAILS_REQUEST',
  SHIPPING_DETAILS_SUCCESS: 'SHIPPING_DETAILS_SUCCESS',
  SHIPPING_DETAILS_FAILURE: 'SHIPPING_DETAILS_FAILURE',
};
