import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { productDetails } from "../../redux/actions/ProductDetailsAction";
import { addCartData } from "../../redux/actions/CartAction";

import { useParams } from "react-router-dom";
import { Badge } from "react-bootstrap";

import { BASE_URL } from "../../axios/API";
import LocalStorage from "../../utils/LocalStorage";
import Loader from "../../components/Loader";
import Login from "../../components/Auth";
import { Helmet } from "react-helmet";

function ProductDetails(props) {
  const [count, setCount] = useState(0);
  const [selectedImage, setSelectedImage] = useState();
  const [selectedVariant, setSelectedVariant] = useState(0);
  const [variant, setVariant] = useState(null);
  const [loading, setLoading] = useState(true);
  const [modalShow, setModalShow] = React.useState(false);

  const user = LocalStorage.getUserDetails();

  let params = useParams();
  let setting = LocalStorage.getSetting();
  useEffect(() => {
    props.productDetails(params.slug);
    setLoading(false);
  }, []);

  const { data } = props;
  let images = [];
  if (data && data.product) {
    images = data.product.photo.split(",");
  }

  useEffect(() => {
    if (props.data && props.data.product && props.cart && props.cart.cart) {
      const { cart } = props;
      setVariant(props.data.product.variants[0]);
      if (cart.cart.length > 0) {
        let cartItem = cart.cart.find(
          (item1) =>
            item1.product_id === props.data.product.id &&
            item1.variant_id === props.data.product.variants[0].id
        );
        if (cartItem) {
          setCount(cartItem.quantity);
        }
      }
    }
  }, [props.data]);
  const handleClose = () => setModalShow(false);

  const addToCart = (quantity) => {
    if (!user) {
      setModalShow(true);
      return;
    }
    setCount(1);
    const data = {
      slug: props.data.product.slug,
      quantity: 1,
      variant_id: variant.id,
    };

    props.addCartData(data, "add");
  };

  const updateToCart = (quantity) => {
    const data = {
      slug: props.data.product.slug,
      quantity: 1,
      variant_id: variant.id,
    };
    props.addCartData(data, "update");
  };

  const checkCart = (variant) => {
    if (props.cart && props.cart.cart && props.cart.cart.length > 0) {
      let cartItem = props.cart.cart.find(
        (item1) =>
          item1.product_id === props.data.product.id &&
          item1.variant_id === variant.id
      );
      if (cartItem) {
        setCount(cartItem.quantity);
      } else {
        setCount(0);
      }
    }
  };

  return (
    <section className="shop-single section-padding pt-3">
      {data && data.product && (
        <div className="container">
          <Helmet>
            <meta charSet="utf-8" />
            <title>{ data.product.title}</title>
            <link rel="canonical" href={window.location.href} />
            <meta
              name="description"
              content={ data.product.summery}
              data-react-helmet="true"
            />
            <meta
              name="keywords"
              content={setting.meta_tags}
              data-react-helmet="true"
            />
            <meta
              name="image"
              content={BASE_URL + setting.logo}
              data-react-helmet="true"
            />

            <meta
              property="og:title"
              content={ data.product.title}
              data-react-helmet="true"
            />

            <meta
              property="og:url"
              content={window.location.href}
              data-react-helmet="true"
            />
            <meta
              property="og:description"
              content={ data.product.summery}
              data-react-helmet="true"
            />
            <meta
              property="og:type"
              content="website"
              data-react-helmet="true"
            />
            <meta
              name="og:image"
              content={BASE_URL + setting.logo}
              data-react-helmet="true"
            />
            <meta
              name="twitter:card"
              content="summary_large_image"
              data-react-helmet="true"
            />
            <meta
              name="twitter:creator"
              content="@frontendsourcecode"
              data-react-helmet="true"
            />
            <meta
              name="twitter:title"
              content={ data.product.title}
              data-react-helmet="true"
            />
            <meta
              name="twitter:description"
              content={ data.product.summery}
              data-react-helmet="true"
            />
            <meta
              name="twitter:image"
              content={BASE_URL + setting.logo}
              data-react-helmet="true"
            />
          </Helmet>
          <div className="row">
            <div className="col-md-6">
              <div className="shop-detail-left">
                <div className="shop-detail-slider">
                  <div className="favourite-icon">
                    <a
                      className="fav-btn"
                      title=""
                      data-placement="bottom"
                      data-toggle="tooltip"
                      href="#"
                      data-original-title="59% OFF"
                    >
                      <i className="mdi mdi-tag-outline"></i>
                    </a>
                  </div>
                  {images && images.length > 0 && (
                    <div className="d-flex flex-column">
                      <div className="text-center">
                        <img
                          src={
                            selectedImage
                              ? BASE_URL + selectedImage
                              : BASE_URL + images[0]
                          }
                          className="img-fluid"
                          style={{ maxHeight: 500, minHeight: 300 }}
                        />
                      </div>
                      <div className="d-flex flex-row justify-content-center">
                        {images.map((item, index) => {
                          return (
                            <img
                              src={BASE_URL + item}
                              key={index}
                              onClick={() => setSelectedImage(item)}
                              style={{
                                height: 60,
                                width: 60,
                                border: "1px solid #e6e6e6",
                                borderRadius: 5,
                                margin: 2,
                              }}
                            ></img>
                          );
                        })}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="shop-detail-right">
                <span className="badge badge-success">
                  {data.product.variants[selectedVariant].discount}% OFF
                </span>
                <h2>{data.product.title}</h2>
                <h6>
                  <strong>
                    <span className="mdi mdi-approval"></span> Available in
                  </strong>{" "}
                  {data.product.variants.map((item1, i) => {
                    return (
                      <span key={i}>
                        {item1.measurement}
                        {item1.unit},{" "}
                      </span>
                    );
                  })}
                </h6>
                <p className="regular-price">
                  <i className="mdi mdi-tag-outline"></i> MRP :{" "}
                  {setting && setting.currency}
                  {data.product.variants[selectedVariant].price}
                </p>
                <p className="offer-price mb-0">
                  Discounted price :{" "}
                  <span className="text-success">
                    {setting && setting.currency}
                    {
                      data.product.variants[selectedVariant]
                        .price_after_discount
                    }
                  </span>
                </p>

                <div className="d-flex flex-row flex-wrap mt-3">
                  {data.product.variants.map((item, index) => {
                    return (
                      <div
                        className={`variant-container ${
                          selectedVariant === index ? "selected" : ""
                        }`}
                        key={index}
                        onClick={() => {
                          setSelectedVariant(index);
                          setVariant(item);
                          checkCart(item);
                        }}
                      >
                        <div>
                          @ {item.measurement}
                          {item.unit}
                          <br />
                          <strong>
                            {setting && setting.currency}{" "}
                            {item.price_after_discount}
                          </strong>
                          <span className="ms-2">
                            <s>
                              {setting && setting.currency} {item.price}
                            </s>
                          </span>
                        </div>
                      </div>
                    );
                  })}
                </div>

                {count === 0 ? (
                  <button
                    type="button"
                    className="btn btn-secondary btn-sm float-right"
                    onClick={() => {
                      addToCart(count + 1);
                    }}
                  >
                    <i className="mdi mdi-cart-outline"></i> Add To Cart
                  </button>
                ) : (
                  <div className="d-flex flex-row justify-content-between align-items-center btn-secondary btn-sm float-right pt-2 pb-2 w-25 mt-5 mb-5">
                    <i
                      className="mdi mdi-minus"
                      onClick={() => {
                        updateToCart(count - 1);
                        setCount(count - 1);
                      }}
                      style={{ height: 20, width: 20 }}
                    ></i>
                    <div>{count}</div>
                    <i
                      className="mdi mdi-plus"
                      onClick={() => {
                        addToCart(count + 1);
                        setCount(count + 1);
                      }}
                      style={{ height: 20, width: 20 }}
                    ></i>
                  </div>
                )}
                <div className="short-description">
                  <h5>
                    Quick Overview
                    <p className="float-right">
                      Availability:{" "}
                      <span className="badge badge-success">In Stock</span>
                    </p>
                  </h5>

                  <p className="mb-0">
                    <span
                      dangerouslySetInnerHTML={{
                        __html: data.product.description,
                      }}
                    />
                  </p>
                </div>
                <h6 className="mb-3 mt-4">Why shop from {setting.title}?</h6>
                <div className="row">
                  <div className="col-md-6">
                    <div className="feature-box">
                      <i className="mdi mdi-truck-fast"></i>
                      <h6 className="text-info">Free Delivery</h6>
                      <p>Lorem ipsum dolor...</p>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="feature-box">
                      <i className="mdi mdi-basket"></i>
                      <h6 className="text-info">100% Guarantee</h6>
                      <p>Rorem Ipsum Dolor sit...</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <Loader loading={props.isLoading} />
      <Login modalShow={modalShow} handleClose={handleClose} />
    </section>
  );
}

const mapStoreToProps = (state) => ({
  isLoading: state.ProductDetailsReducer.isLoading,
  data: state.ProductDetailsReducer.product,
  cart: state.CartReducer.cart,
  error: state.ProductDetailsReducer.error,
});
const mapDispatchToProps = (dispatch) => ({
  productDetails: (slug) => dispatch(productDetails(slug)),
  addCartData: (data, option) => dispatch(addCartData(data, option)),
});

export default connect(mapStoreToProps, mapDispatchToProps)(ProductDetails);
