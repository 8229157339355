import React, { useEffect } from "react";
import BannerSection from "./BannerSection";
import ProductSection from "./ProductSection";
import ProductSlider from "./ProductSlider";
import PromotionSlider from "./PromotionSlider";
import { connect } from "react-redux";
import { homeData, topCategoryData } from "../../redux/actions/HomeAction";
import "./style.css";

import TopCategorySection from "./TopCategorySection";
import LocalStorage from "../../utils/LocalStorage";
import { BASE_URL } from "../../axios/API";
import Loader from "../../components/Loader";
import TopCategoryProduct from "./TopCategoryProduct";
import { Helmet } from "react-helmet";
function Home(props) {
  const setting = LocalStorage.getSetting();
  const home = LocalStorage.getHome();
  useEffect(() => {
    props.homeData();
    props.topCategoryData();
  }, []);
  let banners = [];
  let promotions = [];
  let featuredProducts = [];
  let hotProducts = [];
  let newProducts = [];
  let categories = [2];
  let largeBanner=null;
  
  if (home) {
    banners = home.banners;
    promotions = home.promotions;
    categories = home.categories;
    featuredProducts = home.featuredProducts;
    hotProducts = home.hotProducts;
    newProducts = home.newProducts;
    largeBanner = home.largeBanner;
  }

  if (props.data && props.data.banners) {
    banners = props.data.banners;
    promotions = props.data.promotions;
    categories = props.data.categories;
    featuredProducts = props.data.featuredProducts;
    hotProducts = props.data.hotProducts;
    newProducts = props.data.newProducts;
    largeBanner = props.data.largeBanner;
  }

  return (
    <div className="content-container">

{setting ? (
        <Helmet>
          <title>{setting.title}</title>
          <meta
            name="description"
            content={setting.short_des}
            data-react-helmet="true"
          />
          <meta
            property="og:title"
            content={setting.title}
            data-react-helmet="true"
          />

          
          <meta
            property="og:description"
            content={setting.short_des}
            data-react-helmet="true"
          />
         
          <meta
            name="twitter:title"
            content={setting.title}
            data-react-helmet="true"
          />
          <meta
            name="twitter:description"
            content={setting.short_des}
            data-react-helmet="true"
          />
         
        </Helmet>
      ) : null}

      <BannerSection banners={banners} />
      <TopCategorySection categories={categories} />
      <ProductSection
        title="Best Selling Product"
        subTitle=""
        featuredProducts={featuredProducts}
        setting={setting}
      />
      <PromotionSlider promotions={promotions} />
      <ProductSlider
        title="Hot Product"
        subTitle=""
        products={hotProducts}
        setting={setting}
      />
      
      <ProductSlider
        title="New Product"
        subTitle=""
        products={newProducts}
        setting={setting}
      />
      {props.topCategory && props.topCategory.categoryProducts&&<TopCategoryProduct data={props.topCategory}  setting={setting}/> }
      {largeBanner && 
      <img src={BASE_URL+largeBanner.photo} alt={BASE_URL+largeBanner.photo} style={{width:'100%'}}/>}
      <Loader loading={props.isLoading} />
    </div>
  );
}

const mapStoreToProps = (state) => ({
  isLoading: state.HomeReducer.isLoading,
  data: state.HomeReducer.homeData,
  topCategory: state.HomeReducer.topCategoryData,
  error: state.HomeReducer.error,
});
const mapDispatchToProps = (dispatch) => ({
  homeData: () => dispatch(homeData()),
  topCategoryData: () => dispatch(topCategoryData()),
});

export default connect(mapStoreToProps, mapDispatchToProps)(Home);
