import { combineReducers } from "redux";
import { LoginReducer } from "./LoginReducer";
import { RegisterReducer } from "./RegisterReducer";
import { ResendActivationReducer } from "./ResendActivationReducer";
import { OTPVerificationReducer } from "./OTPVerificationReducer";
import { ForgotPasswordReducer } from "./ForgotPasswordReducer";
import { HomeReducer } from "./HomeReducer";
import { SettingReducer } from "./SettingReducer";
import { CategoryReducer } from "./CategoryReducer";
import { WishlistReducer } from "./WishlistReducer";
import { CartReducer } from "./CartReducer";
import { ProductReducer } from "./ProductReducer";
import { ProductDetailsReducer } from "./ProductDetailsReducer";
import { AddressReducer } from "./AddressReducer";
import { BrandReducer } from "./BrandReducer";
import { ShippingDetailsReducer } from "./ShippingDetailsReducer";

const rootReducer = combineReducers({
  LoginReducer,
  RegisterReducer,
  ResendActivationReducer,
  OTPVerificationReducer,
  ForgotPasswordReducer,
  HomeReducer,
  CategoryReducer,
  CartReducer,
  WishlistReducer,
  ProductReducer,
  ProductDetailsReducer,
  AddressReducer,
  BrandReducer,
  SettingReducer,
  ShippingDetailsReducer
});

export default rootReducer;
