import React from "react";
import { Badge } from "react-bootstrap";
import ProductItem from "../../components/ProductItem";

function ProductSection(props) {
  const { featuredProducts } = props;
  return (
    <section className="product-items-slider section-padding">
      <div className="container">
        <div className="section-header">
          <h5 className="heading-design-h5 d-flex flex-row justify-content-between">
            <h5>
              {props.title}{" "}
              {props.subTitle && props.subTitle.length > 0 && (
                <Badge bg="primary"> {props.subTitle}</Badge>
              )}
            </h5>
            <a className="float-right text-secondary" href="#">
              View All
            </a>
          </h5>
        </div>
        <div className="row g-0">
          {featuredProducts &&
            featuredProducts.length > 0 &&
            featuredProducts.map((item, index) => {
              return (
                <div className="col-lg-3 col-md-4 col-sm-6" key={index}>
                  <ProductItem item={item} setting={props.setting} />
                </div>
              );
            })}
        </div>
      </div>
    </section>
  );
}

export default ProductSection;
