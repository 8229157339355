import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { addAddressData, addressData } from "../../redux/actions/AddressAction";
import { shippingDetailsData } from "../../redux/actions/ShippingDetailsAction";
import Accordion from "react-bootstrap/Accordion";
import ProductImage1 from "../../assets/images/item/1.jpg";
import ProductImage2 from "../../assets/images/item/2.jpg";
import ProductImage3 from "../../assets/images/item/3.jpg";
import { BASE_URL } from "../../axios/API";
import LocalStorage from "../../utils/LocalStorage";
import Loader from "../../components/Loader";
import {
  applyCoupon,
  placeOrder,
  razorpayPayment,
} from "../../axios/ServerRequest";
import toast, { Toaster } from "react-hot-toast";
import { Helmet } from "react-helmet";
import useRazorpay from "react-razorpay";

function Checkout(props) {
  const [coupons, setCoupons] = useState([]);
  const [selectedCoupon, setSelectedCoupon] = useState(null);
  const [shipingCharge, setShippingCharge] = useState(null);
  const [paymentSetting, setPaymentSetting] = useState([]);
  const [paymentOption, setPaymentOption] = useState([]);
  const [razorPayKey, setRazorPayKey] = useState("");
  const [totalAmount, setToatlAmount] = useState(0.0);
  const [selectedAddress, setSelectedAddress] = useState(null);
  const [addresses, setAddresses] = useState(null);
  const [addAddress, setAddAddress] = useState(true);
  const [activekey, setActiveKey] = useState("0");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [city, setCity] = useState("");
  const [pinCode, setPinCode] = useState("");
  const [address, setAddress] = useState("");

  const [firstNameError, setFirstNameError] = useState("");
  const [lastNameError, setLastNameError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [phoneError, setPhoneError] = useState("");
  const [cityError, setCityError] = useState("");
  const [pinCodeError, setPinCodeError] = useState("");
  const [addressError, setAddressError] = useState("");
  const [loading, setLoading] = useState(false);

  const setting = LocalStorage.getSetting();
  const userLocation = LocalStorage.getUserLocation();

  const Razorpay = useRazorpay();

  let locations = new Array();
  if (setting && setting.availability) {
    locations = setting.availability.split(",");
  }

  useEffect(() => {
    props.shippingDetailsData();
    props.addressData();
  }, []);

  useEffect(() => {
    if (props.data && props.data.cart && props.data.cart.length === 0) {
      window.location.href = "/";
    }
  }, [props.data]);

  useEffect(() => {
    if (
      props.addresses &&
      props.addresses.addresses &&
      props.addresses.addresses.length > 0
    ) {
      setAddAddress(false);
      setAddresses(props.addresses.addresses);
      let addr = props.addresses.addresses.find(
        (item) => item.default_address === 1
      );
      if (addr) {
        setSelectedAddress(addr);
      } else {
        setSelectedAddress(props.addresses.addresses[0]);
      }
    }
  }, [props.addresses]);

  useEffect(() => {
    if (props.shippingDetails && props.shippingDetails.coupons) {
      setCoupons(props.shippingDetails.coupons);
    }
    if (props.shippingDetails && props.shippingDetails.payment_settings) {
      setPaymentSetting(props.shippingDetails.payment_settings);
    }
  }, [props.shippingDetails]);

  const saveAddress = () => {
    setFirstNameError("");
    setPhoneError("");
    setEmailError("");
    setPinCodeError("");
    setCityError("");
    setAddressError("");
    if (firstName.length < 3) {
      setFirstNameError("Enter valid name.");
    } else if (phone.length < 10) {
      setPhoneError("Enter valid phone number.");
    } else if (email.length < 10) {
      setEmailError("Enter valid email address.");
    } else if (city.length === 0) {
      setCityError("select a valid city");
    } else if (pinCode.length < 6) {
      setPinCodeError("Enter valid pincode");
    } else if (address.length < 10) {
      setPinCodeError("Please provide the number and street.");
    } else {
      const data = {
        first_name: firstName,
        last_name: lastName,
        email: email,
        phone: phone,
        city: city,
        post_code: pinCode,
        address1: address,
        default_address: 1,
      };
      props.addAddress(data, "add");
    }
  };

  const applyDiscount = async (item) => {
    await applyCoupon(item.code)
      .then((response) => {
        if (response.status === 200) {
          setSelectedCoupon(item);
        }

        toast.success(response.data.message, {
          duration: 4000,
          position: "top-right",
        });
      })
      .catch((error) => {
        console.log(error);
        toast("You can not apply this coupon. Coupon code not found", {
          duration: 4000,
          position: "top-right",
        });
      });
  };

  let total_amount = 0.0;
  let subTotal = 0.0;
  let discount = 0.0;
  let shipping_charges = 0.0;
  let shippingData = null;
  let sub_total = 0.0;
  if (props.data && props.data.total_amount) {
    sub_total = parseFloat(props.data.total_amount.replace(/,/g, ""));
    total_amount = sub_total;
  }
  if (selectedCoupon && selectedCoupon !== null) {
    if (selectedCoupon.type === "percent") {
      discount = (sub_total * selectedCoupon.value) / 100;
      total_amount = total_amount - discount;
    } else {
      discount = selectedCoupon.value;
      total_amount = sub_total - parseFloat(discount.replace(/,/g, ""));
    }
  }
  if (
    props.shippingDetails &&
    props.shippingDetails.shippingCharges &&
    props.shippingDetails.shippingCharges.length > 0
  ) {
    if (props.data && props.data.total_amount) {
      shippingData = props.shippingDetails.shippingCharges.filter(
        (item) =>
          parseFloat(item.minimum_order_amount.replace(/,/g, "")) <=
          total_amount
      );

      if (shippingData && shippingData !== null && shippingData.length > 0) {
        shipping_charges = shippingData[shippingData.length - 1].price;
        shippingData = shippingData[shippingData.length - 1];
        total_amount = total_amount + parseFloat(shipping_charges);
      }
    }
  }

  const checkoutUser = async (shipping_id, discount) => {
    if (!shipping_id) {
      toast.success("Shipping information not available", {
        duration: 4000,
        position: "top-right",
      });
      return;
    } else if (shipping_id.length == 0) {
      toast.success("Shipping information not available", {
        duration: 4000,
        position: "top-right",
      });
      return;
    } else if (paymentOption.length === 0) {
      toast.success("You have not selected any payment option.", {
        duration: 4000,
        position: "top-right",
      });
      return;
    } else if (!selectedAddress || selectedAddress === null) {
      toast.success("You have not selected delivery address", {
        duration: 4000,
        position: "top-right",
      });
      return;
    }
    const data = {
      shipping_id: shipping_id,
      first_name: selectedAddress.first_name,
      last_name: selectedAddress.last_name,
      address1: selectedAddress.address1,
      city: selectedAddress.city,
      post_code: selectedAddress.post_code,
      phone: selectedAddress.phone,
      email: selectedAddress.email,
      payment_method: paymentOption,
      country: "IN",
      coupon: discount,
    };
    setLoading(true);
    await placeOrder(data)
      .then((response) => {
        if (response.status === 200) {
          toast.success("Order Placed Successfully !", {
            duration: 4000,
            position: "top-right",
          });
          if (paymentOption === "Cash on Delivery") {
            window.location.href = "/thankyou";
          } else if (paymentOption === "Razorpay") {
            handleRazorPayPayment(response.data);
          }
        }
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        toast.success("Error occured. Please try later !", {
          duration: 4000,
          position: "top-right",
        });
        setLoading(false);
      });
  };

  const handleRazorPayPayment = async (params) => {
    const options = {
      key: razorPayKey, // Enter the Key ID generated from the Dashboard
      amount: params.order.total_amount, // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
      currency: "INR",
      name: params.order.first_name,
      description: "Payment for Online Purchase",
      image: BASE_URL + setting.logo,

      order_id: params.payment.payment_id, //This is a sample Order ID. Pass the `id` obtained in the response of createOrder().
      handler: function (response) {
        createRazorpayPayment(
          response.razorpay_payment_id,
          response.razorpay_order_id,
          response.razorpay_signature
        );
      },
      prefill: {
        name: params.order.first_name,
        email: params.order.email,
        contact: params.order.phone,
      },
      notes: {
        address: setting.address,
      },
      theme: {
        color: "#3399cc",
      },
      modal: {
        ondismiss: function () {
          alert("You have closed your payment process.");
          window.location.href = "/cart";
        },
      },
    };

    const rzp1 = new Razorpay(options);

    rzp1.on("payment.failed", function (response) {
      window.location.href = "/cart";
    });

    rzp1.open();
  };

  const createRazorpayPayment = async (
    razorpay_payment_id,
    razorpay_order_id,
    razorpay_signature
  ) => {
    const data = {
      razorpay_order_id,
      razorpay_payment_id,
      razorpay_signature,
    };

    await razorpayPayment(data)
      .then((res) => {
        if (res.status === 200) {
          window.location.href = "/thankyou";
        }
      })
      .catch((err) => {
        console.log(err);
        
      });
  };

  return (
    <section className="checkout-page section-padding">
      <Helmet>
        <meta charSet="utf-8" />
        <title>{setting.title} | Checkout</title>
        <link rel="canonical" href={window.location.href} />
        <meta
          name="description"
          content={setting.short_des}
          data-react-helmet="true"
        />
        <meta
          name="keywords"
          content={setting.meta_tags}
          data-react-helmet="true"
        />
        <meta
          name="image"
          content={BASE_URL + setting.logo}
          data-react-helmet="true"
        />

        <meta
          property="og:title"
          content={setting.title}
          data-react-helmet="true"
        />

        <meta
          property="og:url"
          content={window.location.href}
          data-react-helmet="true"
        />
        <meta
          property="og:description"
          content={setting.short_des}
          data-react-helmet="true"
        />
        <meta property="og:type" content="website" data-react-helmet="true" />
        <meta
          name="og:image"
          content={BASE_URL + setting.logo}
          data-react-helmet="true"
        />
        <meta
          name="twitter:card"
          content="summary_large_image"
          data-react-helmet="true"
        />
        <meta
          name="twitter:creator"
          content="@frontendsourcecode"
          data-react-helmet="true"
        />
        <meta
          name="twitter:title"
          content={setting.title}
          data-react-helmet="true"
        />
        <meta
          name="twitter:description"
          content={setting.short_des}
          data-react-helmet="true"
        />
        <meta
          name="twitter:image"
          content={BASE_URL + setting.logo}
          data-react-helmet="true"
        />
      </Helmet>
      <div className="container">
        {props.data && props.data.cart && (
          <div className="row">
            <div className="col-md-8">
              <div className="checkout-step">
                <Accordion defaultActiveKey="0" activeKey={activekey}>
                  <Accordion.Item eventKey="0">
                    <Accordion.Header>
                      Delivered to :
                      {selectedAddress
                        ? `${
                            selectedAddress.first_name +
                            "-" +
                            selectedAddress.post_code
                          }`
                        : ""}{" "}
                    </Accordion.Header>
                    <Accordion.Body>
                      {addAddress ? (
                        <form>
                          <div className="row">
                            <div className="col-sm-6">
                              <div className="form-group">
                                <label className="control-label">
                                  First Name <span className="required">*</span>
                                </label>
                                <input
                                  className="form-control border-form-control"
                                  value={firstName}
                                  placeholder="Enter first name"
                                  type="text"
                                  onChange={(e) => {
                                    setFirstName(e.target.value);
                                  }}
                                  maxLength={20}
                                />
                                {firstNameError.length > 0 && (
                                  <small className="text-danger">
                                    {firstNameError}
                                  </small>
                                )}
                              </div>
                            </div>
                            <div className="col-sm-6">
                              <div className="form-group">
                                <label className="control-label">
                                  Last Name <span className="required">*</span>
                                </label>
                                <input
                                  className="form-control border-form-control"
                                  value={lastName}
                                  placeholder="Enter last name"
                                  type="text"
                                  onChange={(e) => {
                                    setLastName(e.target.value);
                                  }}
                                />
                                {lastNameError.length > 0 && (
                                  <small className="text-danger">
                                    {lastNameError}
                                  </small>
                                )}
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-sm-6">
                              <div className="form-group">
                                <label className="control-label">
                                  Phone <span className="required">*</span>
                                </label>
                                <input
                                  className="form-control border-form-control"
                                  value={phone}
                                  placeholder="Enter phone number"
                                  type="number"
                                  onChange={(e) => {
                                    setPhone(e.target.value);
                                  }}
                                  maxLength={10}
                                />
                                {phoneError.length > 0 && (
                                  <small className="text-danger">
                                    {phoneError}
                                  </small>
                                )}
                              </div>
                            </div>
                            <div className="col-sm-6">
                              <div className="form-group">
                                <label className="control-label">
                                  Email Address{" "}
                                  <span className="required">*</span>
                                </label>
                                <input
                                  className="form-control border-form-control "
                                  value={email}
                                  placeholder="Enter email address"
                                  disabled=""
                                  type="email"
                                  maxLength={50}
                                  onChange={(e) => {
                                    setEmail(e.target.value);
                                  }}
                                />
                                {emailError.length > 0 && (
                                  <small className="text-danger">
                                    {emailError}
                                  </small>
                                )}
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-sm-6">
                              <div className="form-group">
                                <label className="control-label">
                                  City <span className="required">*</span>
                                </label>
                                <select
                                  className="select2 form-control border-form-control"
                                  value={city}
                                  onChange={(e) => setCity(e.target.value)}
                                >
                                  <option value="">Select City</option>
                                  {locations &&
                                    locations.map((item, index) => {
                                      return (
                                        <option
                                          value={item}
                                          key={index}
                                          selected={
                                            userLocation === item ? true : false
                                          }
                                        >
                                          {item}
                                        </option>
                                      );
                                    })}
                                </select>
                                {cityError.length > 0 && (
                                  <small className="text-danger">
                                    {cityError}
                                  </small>
                                )}
                              </div>
                            </div>
                            <div className="col-sm-6">
                              <div className="form-group">
                                <label className="control-label">
                                  Zip Code <span className="required">*</span>
                                </label>
                                <input
                                  className="form-control border-form-control"
                                  value={pinCode}
                                  placeholder="123456"
                                  maxLength={6}
                                  type="number"
                                  onChange={(e) => {
                                    setPinCode(e.target.value);
                                  }}
                                />
                                {pinCodeError.length > 0 && (
                                  <small className="text-danger">
                                    {pinCodeError}
                                  </small>
                                )}
                              </div>
                            </div>
                          </div>

                          <div className="row">
                            <div className="col-sm-12">
                              <div className="form-group">
                                <label className="control-label">
                                  Shipping Address{" "}
                                  <span className="required">*</span>
                                </label>
                                <textarea
                                  className="form-control border-form-control"
                                  onChange={(e) => {
                                    setAddress(e.target.value);
                                  }}
                                >
                                  {address}
                                </textarea>
                                <small className="text-danger">
                                  {addressError}
                                </small>
                              </div>
                            </div>
                          </div>
                          {addAddress ? (
                            <button
                              type="button"
                              className="btn btn-secondary mb-2 mt-2 btn-lg"
                              onClick={saveAddress}
                            >
                              Save Address
                            </button>
                          ) : null}
                        </form>
                      ) : (
                        <div>
                          {addresses &&
                            addresses.length > 0 &&
                            addresses.map((item, index) => {
                              return (
                                <div
                                  className="card"
                                  key={index}
                                  style={{
                                    border: `${
                                      selectedAddress.id === item.id
                                        ? "2px solid #51aa1b"
                                        : ""
                                    }`,
                                  }}
                                  onClick={() => setSelectedAddress(item)}
                                >
                                  <div className="card-body">
                                    <strong>
                                      {item.first_name}{" "}
                                      {item.last_name ? item.last_name : ""}
                                    </strong>
                                    <div>{item.phone}</div>
                                    <div>{item.email}</div>
                                    <div>{item.address1}</div>
                                    <div>{item.address2}</div>
                                    <div>{item.land_mark}</div>
                                    <div>
                                      {item.city}-{item.post_code}
                                    </div>
                                  </div>
                                </div>
                              );
                            })}
                          <button
                            type="button"
                            className="btn btn-secondary m-2 btn-lg"
                            onClick={() =>
                              setActiveKey(`${parseInt(activekey) + 1}`)
                            }
                          >
                            NEXT
                          </button>
                        </div>
                      )}
                    </Accordion.Body>
                  </Accordion.Item>

                  <Accordion.Item eventKey="1">
                    <Accordion.Header>
                      Confirm Cart({props.data.cart.length})
                    </Accordion.Header>
                    <Accordion.Body>
                      {props.data.cart.map((item, index) => {
                        return (
                          <div className="cart-list-product" key={index}>
                            <img
                              className="img-fluid"
                              src={BASE_URL + item.photo.split(",")[0]}
                              alt={BASE_URL + item.photo.split(",")[0]}
                            />
                            <h5>
                              <a href="#">{item.title}</a>
                            </h5>
                            <h6>
                              <strong>
                                <span className="mdi mdi-approval"></span>{" "}
                                Available in
                              </strong>{" "}
                              - {item.attribute} {item.unit}
                            </h6>
                            <p className="offer-price mb-0">
                              {setting.currency} {item.price} X {item.quantity}{" "}
                              = {setting.currency} {item.price * item.quantity}
                            </p>
                          </div>
                        );
                      })}

                      <button
                        type="button"
                        className="btn btn-secondary m-2 btn-lg"
                        onClick={() =>
                          setActiveKey(`${parseInt(activekey) + 1}`)
                        }
                      >
                        NEXT
                      </button>
                      <button
                        type="button"
                        className="btn btn-secondary m-2 btn-lg"
                        onClick={() =>
                          setActiveKey(`${parseInt(activekey) - 1}`)
                        }
                      >
                        Previous
                      </button>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="2">
                    <Accordion.Header>Apply Coupon</Accordion.Header>
                    <Accordion.Body>
                      {coupons &&
                        coupons.length > 0 &&
                        coupons.map((item, index) => {
                          return (
                            <div className="card" key={index}>
                              <div className="card-body">
                                <div className="d-flex flex-row justify-content-between">
                                  <div className="d-flex flex-column">
                                    <b style={{ fontSize: 20 }}>{item.code}</b>
                                    <p>
                                      Get{" "}
                                      {item.type === "fixed"
                                        ? setting.current
                                        : ""}
                                      {item.value}
                                      {item.type === "fixed" ? "" : "%"} OFF on
                                      purchase of {setting.currency}
                                      {item.cart_value}.{" "}
                                      {item.once_use
                                        ? "This offer can be applicable for first time purchase."
                                        : ""}
                                    </p>
                                  </div>
                                  {selectedCoupon &&
                                  selectedCoupon.id === item.id ? (
                                    <button
                                      type="button"
                                      className="btn btn-secondary m-2 btn-lg"
                                      onClick={() => setSelectedCoupon(null)}
                                    >
                                      Cancel
                                    </button>
                                  ) : parseFloat(total_amount) >
                                    parseFloat(
                                      item.cart_value.replace(/,/g, "")
                                    ) ? (
                                    <button
                                      type="button"
                                      className="btn btn-secondary m-2 btn-lg"
                                      onClick={() => applyDiscount(item)}
                                    >
                                      Apply
                                    </button>
                                  ) : null}
                                </div>
                              </div>
                            </div>
                          );
                        })}

                      <button
                        type="button"
                        className="btn btn-secondary m-2 btn-lg"
                        onClick={() =>
                          setActiveKey(`${parseInt(activekey) + 1}`)
                        }
                      >
                        NEXT
                      </button>
                      <button
                        type="button"
                        className="btn btn-secondary m-2 btn-lg"
                        onClick={() =>
                          setActiveKey(`${parseInt(activekey) - 1}`)
                        }
                      >
                        Previous
                      </button>
                    </Accordion.Body>
                  </Accordion.Item>
                  <Accordion.Item eventKey="3">
                    <Accordion.Header>Select Payment Method</Accordion.Header>
                    <Accordion.Body>
                      {paymentSetting &&
                        paymentSetting.length > 0 &&
                        paymentSetting.map((item, index) => {
                          return (
                            <div className="form-check" key={index}>
                              <input
                                className="form-check-input"
                                type="radio"
                                name="payment"
                                value={item.provider}
                                checked={paymentOption === item.provider}
                                id={`flexRadioDefault${index}`}
                                onChange={(e) => {
                                  setPaymentOption(e.target.value);
                                  setRazorPayKey(item.client_id);
                                }}
                              />
                              <label
                                className="form-check-label"
                                htmlFor={`flexRadioDefault${index}`}
                              >
                                {item.provider}
                              </label>
                            </div>
                          );
                        })}

                      <button
                        type="button"
                        className="btn btn-secondary m-2 btn-lg"
                        onClick={() =>
                          setActiveKey(`${parseInt(activekey) - 1}`)
                        }
                      >
                        Previous
                      </button>
                    </Accordion.Body>
                  </Accordion.Item>
                </Accordion>
              </div>
            </div>
            <div className="col-md-4">
              <div className="card">
                <h5 className="card-header">CART TOTALS</h5>
                <div className="card-body pt-0 pr-0 pl-0 pb-0">
                  <div className="d-flex justify-content-between">
                    <p>Cart Subtotal</p>
                    <p>
                      {setting ? setting.currency : ""}
                      {parseFloat(sub_total).toFixed(2)}
                    </p>
                  </div>

                  <div className="d-flex justify-content-between">
                    <p>Shipping Charge</p>
                    <p>
                      {setting ? setting.currency : ""}
                      {shipping_charges}
                    </p>
                  </div>

                  <div className="d-flex justify-content-between">
                    <p>Discount</p>
                    <p>
                      {setting ? setting.currency : ""}
                      {parseFloat(discount).toFixed(2)}
                    </p>
                  </div>
                  <hr />
                  <div className="d-flex justify-content-between mb-3">
                    <b>Total</b>
                    <b>
                      {setting ? setting.currency : ""}
                      {parseFloat(total_amount).toFixed(2)}
                    </b>
                  </div>
                </div>
                {selectedAddress ? (
                  <button
                    onClick={() => checkoutUser(shippingData.id, discount)}
                    className="btn btn-secondary btn-lg mt-5 mb-3 ms-2 me-2"
                    disabled={loading}
                  >
                    Proceed to Checkout
                  </button>
                ) : null}
              </div>
            </div>
          </div>
        )}
      </div>
      <Loader loading={props.isLoading} />
      <Toaster />
    </section>
  );
}

const mapStoreToProps = (state) => ({
  isLoading: state.CartReducer.isLoading,
  data: state.CartReducer.cart,
  shippingDetails: state.ShippingDetailsReducer.shippingDetailsData,
  addresses: state.AddressReducer.addresses,
  error: state.CartReducer.error,
});
const mapDispatchToProps = (dispatch) => ({
  addAddress: (data, option) => dispatch(addAddressData(data, option)),
  shippingDetailsData: () => dispatch(shippingDetailsData()),
  addressData: () => dispatch(addressData()),
});

export default connect(mapStoreToProps, mapDispatchToProps)(Checkout);
