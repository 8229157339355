import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { cartData } from "../../redux/actions/CartAction";
import LocalStorage from "../../utils/LocalStorage";

function CartMenu(props) {
  const [count, setCount] = useState(0);
  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = () => {
    const user = LocalStorage.getUserDetails();

    if (user !== null) {
      setCount(
        LocalStorage.getCartCount() > 0 ? LocalStorage.getCartCount() : 0
      );
      props.cartData();
    }
  };
  return (
    <a href="/cart" className="btn btn-link border-none">
      <i className="mdi mdi-cart"></i> My Cart{" "}
      <small className="cart-value">
        {props.data && props.data.total_count ? props.data.total_count : LocalStorage.getCartCount()}
      </small>
    </a>
  );
}

const mapStoreToProps = (state) => ({
  isLoading: state.CartReducer.isLoading,
  data: state.CartReducer.cart,
  error: state.CartReducer.error,
});
const mapDispatchToProps = (dispatch) => ({
  cartData: () => dispatch(cartData()),
});

export default connect(mapStoreToProps, mapDispatchToProps)(CartMenu);
