import {constants} from '../constants';

const initialState = {
  isLoading: false,
  addresses: {},
  error: undefined,
};

export function AddressReducer(state = initialState, action) {
  switch (action.type) {
    case constants.ADDRESS_REQUEST:
      return {
        ...state,
        isLoading: true,
        addresses: action.address,
      };
    case constants.ADDRESS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        addresses: action.address,
        error: undefined,
      };
    case constants.ADDRESS_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };

    default:
      return state;
  }
}
