import React from "react";
import Google from "../../assets/images/google.png";
import Apple from "../../assets/images/apple.png";
import PaymentMethod from "../../assets/images/payment_methods.png";
import { BASE_URL } from "../../axios/API";

function Footer({ setting }) {
  let locations = [];
  if (setting && setting.availability) {
    locations = setting.availability.split(",");
  }
  return (
    <div>
      <section className="section-padding bg-white border-top">
        <div className="container">
          <div className="row">
            <div className="col-lg-4 col-sm-6">
              <div className="feature-box">
                <i className="mdi mdi-truck-fast"></i>
                <h6>Free &amp; Next Day Delivery</h6>
                <p>Lorem ipsum dolor sit amet, cons...</p>
              </div>
            </div>
            <div className="col-lg-4 col-sm-6">
              <div className="feature-box">
                <i className="mdi mdi-basket"></i>
                <h6>100% Satisfaction Guarantee</h6>
                <p>Rorem Ipsum Dolor sit amet, cons...</p>
              </div>
            </div>
            <div className="col-lg-4 col-sm-6">
              <div className="feature-box">
                <i className="mdi mdi-tag-heart"></i>
                <h6>Great Daily Deals Discount</h6>
                <p>Sorem Ipsum Dolor sit amet, Cons...</p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="section-padding footer bg-white border-top">
        <div className="container">
          <div className="row">
            <div className="col-lg-3 col-md-3">
              <h4 className="mb-5 mt-0">
                <a className="logo" href="/">
                  {setting && setting.logo ? (
                    <img
                      src={BASE_URL + setting.logo}
                      alt={setting.title}
                      style={{ height: 50 }}
                    />
                  ) : null}
                </a>
              </h4>
              <p className="mb-0">
                <a className="text-dark" href={`tel:${setting && setting.phone ? setting.phone : null}`}>

                  <i className="mdi mdi-phone"></i>
                  {setting && setting.phone ? setting.phone : null}
                </a>
              </p>

              <p className="mb-0">
                <a
                  className="text-success"
                  href={`mailto:${
                    setting && setting.email ? setting.email : ""
                  }?subject = Feedback&body = Message`}
                >
                  <i className="mdi mdi-email"></i>{" "}
                  {setting && setting.email ? setting.email : null}
                </a>
              </p>
              <p className="mb-0">
                <i className="mdi mdi-web"></i>{" "}
                {setting && setting.address ? setting.address : null}
              </p>
            </div>
            <div className="col-lg-2 col-md-2">
              <h6 className="mb-4">TOP CITIES </h6>
              <ul>
                {locations &&
                  locations.map((item, index) => {
                    return (
                      <li key={index}>
                        <a href="#">{item}</a>
                      </li>
                    );
                  })}
                <ul></ul>
              </ul>
            </div>
            <div className="col-lg-2 col-md-2">
              <h6 className="mb-4">CATEGORIES</h6>
              <ul>
                <li>
                  <a href="#">Vegetables</a>
                </li>
                <li>
                  <a href="#">Grocery &amp; Staples</a>
                </li>
                <li>
                  <a href="#">Breakfast &amp; Dairy</a>
                </li>
                <li>
                  <a href="#">Soft Drinks</a>
                </li>
                <li>
                  <a href="#">Biscuits &amp; Cookies</a>
                </li>
                <ul></ul>
              </ul>
            </div>
            <div className="col-lg-2 col-md-2">
              <h6 className="mb-4">ABOUT US</h6>
              <ul>
                <li>
                  <a href="#">Company Information</a>
                </li>
                <li>
                  <a href="#">Careers</a>
                </li>
                <li>
                  <a href="#">Store Location</a>
                </li>
                <li>
                  <a href="#">Affillate Program</a>
                </li>
                <li>
                  <a href="#">Copyright</a>
                </li>
                <ul></ul>
              </ul>
            </div>
            <div className="col-lg-3 col-md-3">
              <h6 className="mb-4">Download App</h6>
              <div className="app">
                <a href="#">
                  <img src={Google} alt="" />
                </a>
                <a href="#">
                  <img src={Apple} alt="" />
                </a>
              </div>
              <h6 className="mb-3 mt-4">GET IN TOUCH</h6>
              <div className="footer-social">
                <a className="btn-facebook" href="#">
                  <i className="mdi mdi-facebook"></i>
                </a>
                <a className="btn-twitter" href="#">
                  <i className="mdi mdi-twitter"></i>
                </a>
                <a className="btn-instagram" href="#">
                  <i className="mdi mdi-instagram"></i>
                </a>
                <a className="btn-whatsapp" href="#">
                  <i className="mdi mdi-whatsapp"></i>
                </a>
                <a className="btn-messenger" href="#">
                  <i className="mdi mdi-facebook-messenger"></i>
                </a>
                <a className="btn-google" href="#">
                  <i className="mdi mdi-google"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="pt-4 pb-4 footer-bottom">
        <div className="container">
          <div className="row no-gutters">
            <div className="col-lg-6 col-sm-6">
              {setting && setting.copyright ? (
                <p className="mt-1 mb-0">
                  <span
                    dangerouslySetInnerHTML={{ __html: setting.copyright }}
                  />
                </p>
              ) : null}
            </div>
            <div className="col-lg-6 col-sm-6 text-right">
              <img alt="osahan logo" src={PaymentMethod} />
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Footer;
