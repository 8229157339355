import { getCart, addToCart, updateToCart } from "../../axios/ServerRequest";
import LocalStorage from "../../utils/LocalStorage";
import { constants } from "../constants";

export const cartData = () => (dispatch) => {
  dispatch({
    type: constants.CART_REQUEST,
    isLoading: true,
  });
  return getCart()
    .then((res) => {
      LocalStorage.setCartCount(res.data.total_count);
      dispatch({
        type: constants.CART_SUCCESS,
        payload: res.data,
      });
      return res;
    })
    .catch((error) => {
      dispatch({
        type: constants.CART_FAILURE,
        error,
      });
      return error;
    });
};

export const addCartData = (data, option) => (dispatch) => {
  dispatch({
    type: constants.CART_REQUEST,
    isLoading: true,
  });
  if (option === "add") {
    return addToCart(data)
      .then((res) => {
        LocalStorage.setCartCount(res.data.total_count);

        dispatch({
          type: constants.ADD_TO_CART_SUCCESS,
          payload: res.data,
        });
        return res;
      })
      .catch((error) => {
        dispatch({
          type: constants.ADD_TO_CART_FAILURE,
          error,
        });
        return error;
      });
  } else {
    return updateToCart(data)
      .then((res) => {
        LocalStorage.setCartCount(res.data.total_count);

        dispatch({
          type: constants.ADD_TO_CART_SUCCESS,
          payload: res.data,
        });
        return res;
      })
      .catch((error) => {
        dispatch({
          type: constants.ADD_TO_CART_FAILURE,
          error,
        });
        return error;
      });
  }
};
