import {getSettingData} from '../../axios/ServerRequest';
import LocalStorage from '../../utils/LocalStorage';
import {constants} from '../constants';

export function isLoading(bool) {
  return {
    type: constants.SETTING_REQUEST,
    isLoading: bool,
  };
}

export function settingSuccess(data) {
  return {
    type: constants.SETTING_SUCCESS,
    data,
  };
}

export function settingFailed(error) {
  return {
    type: constants.SETTING_FAILURE,
    error,
  };
}

export const settingData = () => dispatch => {
  dispatch(isLoading(true));
  return getSettingData()
    .then(res => {
        LocalStorage.setSetting(res.data.setting);
        dispatch(settingSuccess(res.data))
    })
    .catch(error => {
        dispatch(settingFailed(error.response.message))
    });
};
