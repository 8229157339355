import API from './API';


export const userLogin = async data => {
  return await API({
    method: 'POST',
    url: '/api/v1/login',
    data: data,
  }).then(res => {
    return res;
  });
};

export const verifyUser = async data => {
  return await API({
    method: 'POST',
    url: '/api/v1/verifyUser',
    data: data,
  }).then(res => {
    return res;
  });
};

export const userRegister = async data => {
  return await API({
    method: 'POST',
    url: '/api/v1/register',
    data: data,
  }).then(res => {
    return res;
  });
};
export const updateProfile = async data => {
  return await API({
    method: 'POST',
    url: '/api/v1/user/update',
    data: data,
    contentType: "multipart/form-data",
  }).then(res => {
    return res;
  });
};

export const changePassword = async data => {
  return await API({
    method: 'POST',
    url: '/api/v1/change-password',
    data: data,
  }).then(res => {
    return res;
  });
};

export const resendActivationCode = async phone => {
  return await API({
    method: 'GET',
    url: `/api/v1/resend-verification/${phone}`,
  }).then(res => {
    return res;
  });
};

export const verifyOTP = async code => {
  return await API({
    method: 'GET',
    url: `/api/v1/register/activate/${code}`,
  }).then(res => {
    return res;
  });
};

export const createCompany = async data => {
  return await API({
    method: 'POST',
    url: '/api/v1/company',
    data: data,
  }).then(res => {
    return res;
  });
};

export const forgotPassword = async data => {
  return await API({
    method: 'POST',
    url: '/api/v1/forgot-password',
    data: data,
  }).then(res => {
    return res;
  });
};

export const resetPassword = async data => {
  return await API({
    method: 'POST',
    url: '/api/v1/reset-password',
    data: data,
  }).then(res => {
    return res;
  });
};

export const getBanners = async () => {
  return await API({
    method: 'GET',
    url: '/api/v1/banners',
  }).then(res => {
    return res;
  });
};

export const getBrands = async () => {
  return await API({
    method: 'GET',
    url: '/api/v1/brands',
  }).then(res => {
    return res;
  });
};

export const getCategories = async () => {
  return await API({
    method: 'GET',
    url: '/api/v1/categories',
  }).then(res => {
    return res;
  });
};

export const getHomeData = async () => {
  return await API({
    method: 'GET',
    url: '/api/v1/home',
  }).then(res => {
    return res;
  });
};

export const topCategoryWithProduct = async () => {
  return await API({
    method: 'GET',
    url: '/api/v1/topCategoryWithProduct',
  }).then(res => {
    return res;
  });
};

export const getSettingData = async () => {
  return await API({
    method: 'GET',
    url: '/api/v1/settings',
  }).then(res => {
    return res;
  });
};

export const getSearchProduct = async (text, page) => {
  return await API({
    method: 'GET',
    url: `/api/v1/searchProduct?search=${text}&page=${page}`,
  }).then(res => {
    return res;
  });
};

export const getAllCategories = async () => {
  return await API({
    method: 'GET',
    url: `/api/v1/allCategories`,
  }).then(res => {
    return res;
  });
};

export const getProductsByCategory = async (slug,page) => {
  return await API({
    method: 'GET',
    url: `/api/v1/productByCategory/${slug}?page=${page}`,
  }).then(res => {
    return res;
  });
};

export const getProductsBySubCategory = async (slug, page) => {
  return await API({
    method: 'GET',
    url: `/api/v1/productBySubCategory/${slug}?page=${page}`,
  }).then(res => {
    return res;
  });
};

export const getProductDetails = async slug => {
  return await API({
    method: 'GET',
    url: `/api/v1/products/${slug}`,
  }).then(res => {
    return res;
  });
};

export const getCoupons = async () => {
  return await API({
    method: 'GET',
    url: `/api/v1/coupons`,
  }).then(res => {
    return res;
  });
};

export const getCart = async () => {
  return await API({
    method: 'GET',
    url: `/api/v1/cart`,
  }).then(res => {
    return res;
  });
};

export const getWishlist = async () => {
  return await API({
    method: 'GET',
    url: `/api/v1/wishlist`,
  }).then(res => {
    return res;
  });
};

export const addWishlist = async data => {
  return await API({
    method: 'POST',
    url: '/api/v1/wishlist',
    data: data,
  }).then(res => {
    return res;
  });
};

export const deleteWishlist = async id => {
  return await API({
    method: 'POST',
    url: '/api/v1/wishlist/${id}',
    data: {},
  }).then(res => {
    return res;
  });
};

export const addToCart = async data => {
  return await API({
    method: 'POST',
    url: '/api/v1/add-to-cart',
    data: data,
  }).then(res => {
    return res;
  });
};

export const updateToCart = async data => {
  return await API({
    method: 'POST',
    url: '/api/v1/update-to-cart',
    data: data,
  }).then(res => {
    return res;
  });
};

export const placeOrder = async data => {
  return await API({
    method: 'POST',
    url: '/api/v1/orders',
    data: data,
  }).then(res => {
    return res;
  });
};

export const getOrders = async pageCount => {
  return await API({
    method: 'GET',
    url: `/api/v1/orders?page=${pageCount}`,
  }).then(res => {
    return res;
  });
};

export const getOrderDetails = async id => {
  return await API({
    method: 'GET',
    url: `/api/v1/order-details/${id}`,
  }).then(res => {
    return res;
  });
};

export const cancelOrder = async id => {
  return await API({
    method: 'GET',
    url: `/api/v1/orders/cancel/${id}`,
  }).then(res => {
    return res;
  });
};

export const getAddresses = async () => {
  return await API({
    method: 'GET',
    url: `/api/v1/addresses`,
  }).then(res => {
    return res;
  });
};

export const addAddress = async data => {
  return await API({
    method: 'POST',
    url: '/api/v1/address',
    data: data,
  }).then(res => {
    return res;
  });
};

export const updateAddress = async (data, id) => {
  return await API({
    method: 'POST',
    url: `/api/v1/address/update/${id}`,
    data: data,
  }).then(res => {
    return res;
  });
};

export const deleteAddress = async id => {
  return await API({
    method: 'GET',
    url: `/api/v1/address/delete/${id}`,
  }).then(res => {
    return res;
  });
};

export const getShippingDetails = () => {
  return API({
    method: 'GET',
    url: `/api/v1/shippingDetails`,
  }).then(res => {
    return res;
  });
};

export const applyCoupon = code => {
  return API({
    method: 'GET',
    url: `/api/v1/applyCoupon/${code}`,
  }).then(res => {
    return res;
  });
};

export const razorpayPayment = data => {
  return API({
    method: 'POST',
    url: `/api/v1/razorpay/payment`,
    data:data
  }).then(res => {
    return res;
  });
};
