import {getShippingDetails} from '../../axios/ServerRequest';
import {constants} from '../constants';

export function isLoading(bool) {
  return {
    type: constants.SHIPPING_DETAILS_REQUEST,
    isLoading: bool,
  };
}

export function shippingDetailsSuccess(data) {
  return {
    type: constants.SHIPPING_DETAILS_SUCCESS,
    data,
  };
}

export function shippingDetailsFailed(error) {
  return {
    type: constants.SHIPPING_DETAILS_FAILURE,
    error,
  };
}

export const shippingDetailsData = () => dispatch => {
  dispatch(isLoading(true));
  return getShippingDetails()
    .then(res => {
      dispatch(shippingDetailsSuccess(res.data));
    })
    .catch(error => {
      dispatch(shippingDetailsFailed(error.response.message));
    });
};
