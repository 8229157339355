import React from "react";
import OwlCarousel from "react-owl-carousel";
import { BASE_URL } from "../../axios/API";

function BannerSection({ banners }) {
  return (
    <section className="carousel-slider-main text-center border-top border-bottom bg-white position-relative mt-2">
      <OwlCarousel
        className="owl-theme owl-carousel owl-carousel-slider"
        loop
        items={1}
        autoplay
        nav
      >
        {banners &&
          banners.length > 0 &&
          banners.map((item, index) => {
            return (
              <div className="item" key={index}>
                <a href="#">
                  <img
                    style={{ maxHeight: 500 }}
                    className="img-fluid"
                    src={BASE_URL + item.photo}
                    alt={BASE_URL + item.photo}
                  />
                </a>
              </div>
            );
          })}
      </OwlCarousel>
    </section>
  );
}

export default BannerSection;
