import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { BASE_URL } from "../../axios/API";
import LocalStorage from "../../utils/LocalStorage";

import { connect } from "react-redux";
import { login } from "../../redux/actions/LoginAction";
import {
  DEFAULT_RULE,
  NAME_RULE,
  EMAIL_RULE,
  PHONE_RULE,
  PASSWORD_RULE,
} from "../../utils/Validator/Rule";
import validate from "../../utils/Validator/Validator";

function Login(props) {
  const [showLogin, setShowLogin] = useState(true);
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [password, setPassword] = useState("");

  const [nameError, setNameError] = useState("");
  const [emailError, setEmailError] = useState("");
  const [phoneError, setPhoneError] = useState("");
  const [passwordError, setPasswordError] = useState("");

  const setting = LocalStorage.getSetting();
  const emailRegx =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  const phoneRegx = /^[0-9]*$/;

  const handleRegister = () => {
    if (name.length < 3) {
      setNameError("Name should have 3 charactors");
      return;
    } else if (email.length < 10) {
      setEmailError("Please enter Valid email");
      return;
    } else if (!emailRegx.test(email)) {
      setEmailError("Please enter valid email");
      return;
    } else if (phone.length < 10) {
      setPhoneError("Please enter 10 digit phone number");
      return;
    } else if (!phoneRegx.test(phone)) {
      setPhoneError("Please enter 10 digit phone number");
      return;
    } else if (password.length < 6) {
      setPasswordError("Please enter 6  Alpha Numeric password");
      return;
    }
    const userData = {
      name: name,
      email: email,
      phone: phone,
      password: password,
    };
    setLoading(true);
    props.login(userData, "register");
  };

  const handleLogin = () => {
    if (phone.length < 10) {
      setPhoneError("Please enter 10 digit phone number");
      return;
    } else if (password.length < 6) {
      setPasswordError("Please enter 6  Alpha Numeric password");
      return;
    }
    const userData = {
      phone: phone,
      password: password,
    };
    setLoading(true);
    props.login(userData, "login");
  };

  useEffect(() => {
    const error = props.error;
    if (error) {
      setEmailError("");
      setPhoneError("");
      if (error && error.error && error.error.email) {
        setEmailError(error.error.email[0]);
      }
      if (error && error.error && error.error.phone) {
        setPhoneError(error.error.phone[0]);
      }
      if (error && error.message) {
        setEmailError(error.message);
      }
    } else {
      return;
    }

    setLoading(false);
  }, [props.error]);

  useEffect(() => {
    setLoading(false);
    if (props.data && props.data.user) {
      window.location.reload();
    }
  }, [props.data]);

  return (
    <Modal
      className="login-modal-main"
      show={props.modalShow}
      onHide={props.handleClose}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      backdrop="static"
    >
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body>
        <div className="login-modal">
          <div className="row">
            <div className="col-lg-6 pad-right-0">
              <div className="d-flex flex-column justify-content-center align-items-center w-100 h-100">
                {setting && setting.logo ? (
                  <img
                    src={BASE_URL + setting.logo}
                    style={{ maxHeight: 100 }}
                  />
                ) : null}
                {showLogin ? (
                  <p className="mt-3 mb-5">
                    Don't have an account?
                    <a
                      href="#"
                      onClick={() => setShowLogin(false)}
                      style={{ color: "#51aa1b" }}
                    >
                      Please Register
                    </a>
                  </p>
                ) : (
                  <p className="mt-3 mb-5">
                    Already have an account?
                    <a
                      href="#"
                      onClick={() => setShowLogin(true)}
                      style={{ color: "#51aa1b" }}
                    >
                      Please Login
                    </a>
                  </p>
                )}
              </div>
            </div>
            <div className="col-lg-6 pad-left-0">
              <form>
                <div className="login-modal-right">
                  <div className="tab-content">
                    <div
                      className={`tab-pane ${showLogin ? "active" : ""}`}
                      id="login"
                      role="tabpanel"
                    >
                      <h5 className="heading-design-h5">
                        Login to your account
                      </h5>
                      {props.error && props.error.message ? (
                        <div style={{ fontSize: 12, color: "red" }}>
                          {props.error.message}
                        </div>
                      ) : null}
                      <fieldset className="form-group">
                        <label>Enter Email/Mobile number</label>
                        <input
                          type="text"
                          value={phone}
                          onChange={(e) => {
                            setPhone(e.target.value);
                            setPhoneError("");
                          }}
                          className="form-control"
                          placeholder="+91 123 456 7890"
                        />
                        <div style={{ fontSize: 10, color: "red" }}>
                          {phoneError}
                        </div>
                      </fieldset>
                      <fieldset className="form-group">
                        <label>Enter Password</label>
                        <input
                          type="password"
                          className="form-control"
                          placeholder="********"
                          value={password}
                          onChange={(e) => {
                            setPassword(e.target.value);
                            setPasswordError("");
                          }}
                        />
                        <div style={{ fontSize: 10, color: "red" }}>
                          {passwordError}
                        </div>
                      </fieldset>
                      <fieldset className="form-group">
                        <button
                          disabled={props.loading}
                          onClick={handleLogin}
                          type="button"
                          className="btn btn-lg btn-secondary btn-block mt-3 mb-3 w-100"
                        >
                          Enter to your account
                        </button>
                      </fieldset>
                    </div>

                    <div
                      className={`tab-pane ${showLogin ? "" : "active"}`}
                      id="register"
                      role="tabpanel"
                    >
                      <h5 className="heading-design-h5">Register Now!</h5>
                      {props.error && props.error.message ? (
                        <div style={{ fontSize: 10, color: "red" }}>
                          {props.error.message}
                        </div>
                      ) : null}
                      <fieldset className="form-group">
                        <label>Enter Name</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="John Deo"
                          maxLength={20}
                          value={name}
                          onChange={(e) => {
                            setName(e.target.value);
                            setNameError("");
                          }}
                          required
                        />
                        <div style={{ fontSize: 10, color: "red" }}>
                          {nameError}
                        </div>
                      </fieldset>

                      <fieldset className="form-group">
                        <label>Enter Email</label>
                        <input
                          type="email"
                          className="form-control"
                          placeholder="user@email.com"
                          maxLength={50}
                          value={email}
                          onChange={(e) => {
                            setEmail(e.target.value);
                            setEmailError("");
                          }}
                          required
                        />
                        <div style={{ fontSize: 10, color: "red" }}>
                          {emailError}
                        </div>
                      </fieldset>

                      <fieldset className="form-group">
                        <label>Enter Phone</label>
                        <input
                          type="text"
                          className="form-control"
                          maxLength={10}
                          value={phone}
                          onChange={(e) => setPhone(e.target.value)}
                          placeholder="+91 123 456 7890"
                          required
                        />
                        <div style={{ fontSize: 10, color: "red" }}>
                          {phoneError}
                        </div>
                      </fieldset>

                      <fieldset className="form-group">
                        <label>Enter Password</label>
                        <input
                          type="password"
                          className="form-control"
                          maxLength={20}
                          placeholder="********"
                          value={password}
                          onChange={(e) => {
                            setPassword(e.target.value);
                            setPasswordError("");
                          }}
                          required
                        />
                        <div style={{ fontSize: 10, color: "red" }}>
                          {passwordError}
                        </div>
                      </fieldset>

                      <fieldset className="form-group">
                        <button
                          disabled={loading}
                          onClick={handleRegister}
                          type="button"
                          className="btn btn-lg btn-secondary btn-block mt-3 mb-3 w-100"
                        >
                          Create Your Account
                        </button>
                      </fieldset>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}

const mapStoreToProps = (state) => ({
  isLoading: state.LoginReducer.isLoading,
  data: state.LoginReducer.userDetails,
  error: state.LoginReducer.error,
});
const mapDispatchToProps = (dispatch) => ({
  login: (data, type) => dispatch(login(data, type)),
});

export default connect(mapStoreToProps, mapDispatchToProps)(Login);
