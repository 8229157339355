import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { addCartData } from "../../redux/actions/CartAction";
import LocalStorage from "../../utils/LocalStorage";
import Login from "../Auth";
import { BASE_URL } from "../../axios/API";

function ProductItem(props) {
  const [count, setCount] = useState(0);
  const { item, setting } = props;
  const [modalShow, setModalShow] = React.useState(false);

  const user = LocalStorage.getUserDetails();

  useEffect(() => {
    if (props.data && props.data.cart) {
      const {cart} = props.data;
      if(cart.length>0){
         let cartItem = cart.find(item1=>item1.product_id===item.id)
         if(cartItem){
            setCount(cartItem.quantity);
         }
      }
      
    }
  }, [props.data]);

  const handleClose = () => setModalShow(false);

  const addToCart = (quantity) => {
    if(!user){
       setModalShow(true);
    }
    const data = {
      slug: item.slug,
      quantity: 1,
      variant_id: item.variants[0].id,
    };

    props.addCartData(data, "add");
  };

  const updateToCart = (quantity) => {
    const data = {
      slug: item.slug,
      quantity: 1,
      variant_id: item.variants[0].id,
    };
    props.addCartData(data, "update");
  };

  return (
    <div className="product">
      <a href={`/product/${item.slug}`} target="_blank">
        <div className="product-header">
          <span className="badge badge-success">
            {item.variants[0].discount}%
          </span>
          <img
            className="img-fluid"
            src={BASE_URL + item.photo.split(",")[0]}
            alt={BASE_URL + item.photo}
            style={{ maxHeight: 150 }}
          />
          <span className="veg text-success mdi mdi-circle"></span>
        </div>
        <div className="product-body">
          <h5>
            {item.title} <br />
            {item.variants[0].measurement}
            {item.variants[0].unit}
          </h5>
          <h6>
            <strong>
              <span className="mdi mdi-approval"></span> Available in
            </strong>{" "}
            -{" "}
            {item.variants.map((item1, i) => {
              return (
                <span key={i}>
                  {item1.measurement}
                  {item1.unit},{" "}
                </span>
              );
            })}
          </h6>
        </div>
      </a>
      <div className="product-footer d-flex flex-row justify-content-between align-items-center">
        <p className="offer-price mb-0">
          {setting && setting.currency?setting.currency:""}
          {item.variants[0].price_after_discount} (
          {item.variants[0].measurement}
          {item.variants[0].unit})<i className="mdi mdi-tag-outline"></i>
          <br />
          {item.variants[0].price_after_discount ===
          item.variants[0].price ? null : (
            <span className="regular-price">
              {setting&& setting.currency?setting.currency:""}
              {item.variants[0].price}
            </span>
          )}
        </p>
        {count === 0 ? (
          <button
            type="button"
            className="btn btn-secondary btn-sm float-right"
            onClick={() => {
              setCount(count + 1);
              addToCart(count + 1);
            }}
          >
            <i className="mdi mdi-cart-outline"></i> Add To Cart
          </button>
        ) : (
          <div className="d-flex flex-row justify-content-between align-items-center btn btn-secondary btn-sm float-right ">
            <i
              className="mdi mdi-minus"
              onClick={() => {
                setCount(count - 1);
                updateToCart(count - 1);
              }}
              style={{ height: 20, width: 20 }}
            ></i>
            <div>{count}</div>
            <i
              className="mdi mdi-plus"
              onClick={() => {
                setCount(count + 1);
                addToCart(count + 1);
              }}
              style={{ height: 20, width: 20 }}
            ></i>
          </div>
        )}
      </div>
      <Login modalShow={modalShow} handleClose={handleClose} />

    </div>
  );
}

const mapStoreToProps = (state) => ({
  isLoading: state.CartReducer.isLoading,
  data: state.CartReducer.cart,
  error: state.CartReducer.error,
});
const mapDispatchToProps = (dispatch) => ({
  addCartData: (data, option) => dispatch(addCartData(data, option)),
});

export default connect(mapStoreToProps, mapDispatchToProps)(ProductItem);
