import {constants} from '../constants';

const initialState = {
    isLoading: false,
    wishlist:{},
    error:undefined
};

export function WishlistReducer(state = initialState, action) {
  switch (action.type) {
    case constants.WISHLIST_REQUEST:
      return {
        ...state,
        isLoading: true,
        wishlist: action.wishlist,
      };
    case constants.WISHLIST_SUCCESS:
      return {
        ...state,
        isLoading: false,
        wishlist: action.wishlist,
        error:undefined
      };
    case constants.WISHLIST_FAILURE:
      return {
        ...state,
        isLoading: false,
        error:action.error
      };

    default:
      return state;
  }
}
