import React, { useState } from "react";
import { BASE_URL } from "../../axios/API";
import { connect } from "react-redux";
import { addCartData } from "../../redux/actions/CartAction";

function CartItem(props) {
  const { item, setting } = props;
  const [count, setCount] = useState(item.quantity);


  const addToCart = () => {
   
    const data = {
      slug: item.slug,
      quantity: 1,
      variant_id: item.variant_id,
    };

    props.addCartData(data, "add");
  };

  const updateToCart = () => {
    const data = {
      slug: item.slug,
      quantity: 1,
      variant_id: item.variant_id,
    };
    props.addCartData(data, "update");
  };

  const removeCart = () => {
    const data = {
      slug: item.slug,
      quantity: 0,
      variant_id: item.variant_id,
    };
    props.addCartData(data, "update");
  };





  return (
    <tr>
      <td className="cart_product">
        <a href="#">
          <img
            className="img-fluid"
            src={BASE_URL + item.photo.split(",")[0]}
            alt={BASE_URL + item.photo.split(",")[0]}
          />
        </a>
      </td>
      <td className="cart_description">
        <h5 className="product-name">
          <a href="#">{item.title}</a>
        </h5>
        <h6>
          <strong>
            <span className="mdi mdi-approval"></span> Available in
          </strong>{" "}
          - {item.attribute} {item.unit}
        </h6>
      </td>
      <td className="availability in-stock">
        <span className="badge bg-success">In stock</span>
      </td>
      <td className="price">
        <span>
          {setting.currency} {item.price}
        </span>
      </td>
      <td className="qty">
        {count === 0 ? (
          <button
            type="button"
            className="btn btn-secondary btn-sm float-right"
            onClick={() => {
              setCount(count + 1);
              addToCart(count + 1);
            }}
          >
            <i className="mdi mdi-cart-outline"></i> Add To Cart
          </button>
        ) : (
          <div className="d-flex flex-row justify-content-between align-items-center btn btn-secondary btn-sm float-right w-50">
            <i
              className="mdi mdi-minus"
              onClick={() => {
                setCount(count - 1);
                updateToCart(count - 1);
              }}
              style={{ height: 20, width: 20 }}
            ></i>
            <div>{count}</div>
            <i
              className="mdi mdi-plus"
              onClick={() => {
                setCount(count + 1);
                addToCart(count + 1);
              }}
              style={{ height: 20, width: 20 }}
            ></i>
          </div>
        )}
      </td>
      <td className="price text-center">
        <span>{setting.currency} {item.amount}</span>
      </td>
      <td className="action">
        <a
          className="btn btn-sm btn-danger"
          data-original-title="Remove"
          href="#"
          title=""
          data-placement="top"
          data-toggle="tooltip"
          onClick={removeCart}
        >
          <i className="mdi mdi-close-circle-outline"></i>
        </a>
      </td>
    </tr>
  );
}

const mapStoreToProps = (state) => ({
  isLoading: state.CartReducer.isLoading,
  data: state.CartReducer.cart,
  error: state.CartReducer.error,
});
const mapDispatchToProps = (dispatch) => ({
  addCartData: (data, option) => dispatch(addCartData(data, option)),
});

export default connect(mapStoreToProps, mapDispatchToProps)(CartItem);
