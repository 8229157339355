import React, { useEffect, Suspense } from "react";
import { BrowserRouter, useParams } from "react-router-dom";
import { Route, Routes } from "react-router-dom";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { connect } from "react-redux";
import { settingData } from "./redux/actions/SettingAction";
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css

import AOS from "aos";
import "aos/dist/aos.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "./assets/css/style.css";
import "./assets/css/materialdesignicons.min.css";
import AppRoutes from './routes'

import { Helmet } from "react-helmet";
import { BASE_URL } from "./axios/API";


function App(props) {
  useEffect(() => {
    AOS.init();
    props.settingData();
  }, []);

  return (
    <div>
     {props.data && props.data.setting ? (
        <Helmet>
          <meta charSet="utf-8" />
          <link rel='canonical' href={window.location.href} />          
          <meta
            name="keywords"
            content={props.data.setting.meta_tags}
            data-react-helmet="true"
          />
          <meta
            name="image"
            content={BASE_URL + props.data.setting.logo}
            data-react-helmet="true"
          />

          <meta
            property="og:url"
            content={window.location.href}
            data-react-helmet="true"
          />
         
          <meta property="og:type" content="website" data-react-helmet="true" />
          <meta
            name="og:image"
            content={BASE_URL + props.data.setting.logo}
            data-react-helmet="true"
          />
          <meta
            name="twitter:card"
            content="summary_large_image"
            data-react-helmet="true"
          />
          <meta
            name="twitter:creator"
            content="@frontendsourcecode"
            data-react-helmet="true"
          />
          
          <meta
            name="twitter:image"
            content={BASE_URL + props.data.setting.logo}
            data-react-helmet="true"
          />
        </Helmet>
      ) : null}
      <AppRoutes/>
    </div>
  );
}

const mapStoreToProps = (state) => ({
  isLoading: state.SettingReducer.isLoading,
  data: state.SettingReducer.settingData,
  error: state.SettingReducer.error,
});
const mapDispatchToProps = (dispatch) => ({
  settingData: () => dispatch(settingData()),
});

export default connect(mapStoreToProps, mapDispatchToProps)(App);
