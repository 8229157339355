import React, { Suspense } from "react";
import { BrowserRouter, useParams } from "react-router-dom";
import { Route, Routes } from "react-router-dom";
import AboutUs from "../views/AboutUs";
import Profile from "../views/Profile";
import Cart from "../views/Cart";
import Checkout from "../views/Checkout";
import ThankYou from "../views/ThankYou";
import ProductDetails from "../views/ProductDetails";
import Products from "../views/Products";
import DefaultLayout from "../layouts/BaseLayout";
import Home from "../views/Home";
import ProtectedRoute from "./ProtectedRoute";

const AppRoutes = () => {
  const loading = (
    <div className="pt-3 text-center">
      <div className="sk-spinner sk-spinner-pulse"></div>
    </div>
  );

  return (
    <BrowserRouter>
      <Suspense fallback={loading}>
        <Routes>
          <Route path="/" element={<DefaultLayout />}>
            <Route index element={<Home />} />
            <Route path="#" element={<Home />} />
            <Route path="about" element={<AboutUs />} />
            <Route
              path="profile"
              element={
                <ProtectedRoute>
                  <Profile />
                </ProtectedRoute>
              }
            />
            <Route
              path="cart"
              element={
                <ProtectedRoute>
                  <Cart />
                </ProtectedRoute>
              }
            />
            {/* <Route path="products/" element={<Products />} /> */}
            <Route path="/shop/:cat_slug" element={<Products />} />
            <Route
              path="/shop/:cat_slug/:sub_cat_slug"
              element={<Products />}
            />
            <Route path="/shop/search/:text" element={<Products />} />

            <Route path="product/:slug" element={<ProductDetails />} />
            <Route
              path="checkout"
              element={
                <ProtectedRoute>
                  <Checkout />
                </ProtectedRoute>
              }
            />
            <Route path="thankyou" element={<ThankYou />} />
          </Route>
        </Routes>
      </Suspense>
    </BrowserRouter>
  );
};

export default AppRoutes;
